import gen2 from "../assets/others/starlink_gen2.png";

import gen3 from "../assets/others/starlink_gen3.png";
import stants from "../assets/others/yavdag_stants.png";
import standard_gen3 from "../assets/others/standard_gen3.webp";

import indoor_wifi6 from "../assets/others/indoor_wifi6.png";
import outdoor_wifi_small from "../assets/others/outdoor_wifi_small.png";
import outdoor_wifi6_small from "../assets/others/outdoor_wifi6_small.png";
import outdoor_wifi6 from "../assets/others/outdoor_wifi6.png";
import starlink_carry_bag from "../assets/others/starlink_carry_bag.png";
import sbattery from "../assets/gallery-img/sbattery_3.png";
import u3 from "../assets/gallery/star.png";
import u4 from "../assets/gallery/tsaa.png";
import gen3_u1 from "../assets/gallery/gen3_u1.png";
import gen3_u2 from "../assets/gallery/gen3_u2.png";
import gen3_achaa_suuri from "../assets/gallery/gen3_achaa_suuri.png";
import batterysmall from "../assets/gallery/batterysmall.png";
import batterysmall1 from "../assets/gallery/batterysmall1.png";
import solar_panel from "../assets/others/solar_panel.png";
import gen2_9 from "../assets/gallery-img/gen2_9.png";

import car_charger_cable from "../assets/others/car_charger_cable.png";
import solar_panel_unfolded from "../assets/others/solar_panel_unfolded.png";
import charger_220 from "../assets/others/220charger.png";

import salaalagch_12v from "../assets/additional/12v_salaalagch.png";
import zalguur_12V from "../assets/additional/12v_zalguur.png";
import inverter_220 from "../assets/additional/inverter.png";
import regulator from "../assets/additional/regulator.png";
import regulator2 from "../assets/additional/regulator2.jpg";
import zalguur_220 from "../assets/additional/220v_zalguur.png";
import acc_zalguur from "../assets/additional/Acc_zalguur.png";
import soronzon_suuri from "../assets/additional/soronzon_suuri.png";
import zalguur from "../assets/additional/zalguur.png";
import gen2suuri from "../assets/additional/Gen2 suuri 2.png";
import tolgoi from "../assets/additional/tawag 2.png";
import holbohturba from "../assets/additional/turba2 2.png";
import g45 from "../assets/additional/Gradus 1.png";
import behleh from "../assets/additional/suuri 2.png";
import more from "../assets/device-icons/more.png";

export const products2 = [
  {
    id: 1,
    name: "Starlink Nomad - Явдаг интернэт (Gen 3)",
    img: gen3,
    img1: u3,
    img2: u4,
    video: "xd7BpJkJdDQ?si=W138WdCwQmPOmmxh",
    price: 6500000,
    headerText:
      "Монгол орны байгаль цаг агаар, замын нөхцөл, аврагчид, аялагчид, малчдад зориулан өөрчилж хялбаршуулсан Старлинкийн сансрын интернэтийн хөдөлгөөнт төхөөрөмж Starlink Nomad.",
    descMain:
      "Starlink Nomad Gen3 нь манай хамгийн сүүлд гаргасан хөдөлгөөнт загвар юм.",
    descriptionObject: [
      {
        id: 1,
        value: [
          "Өмнөх Gen2 загвараас 30%-р интернэтийн хурд, сүлжээний тогтвортой байдлаар илүү.",
        ],
      },
      { id: 2, value: ["Gen2 -c 5 дахин хурдан сүлжээнд холбогдоно."] },
      {
        id: 3,
        value: [
          "Gen2 нь WiFi 5 байдаг бол Gen3-ын WiFi6 цацагч илүү өргөн талбайд сүлжээ цацна",
        ],
      },
      { id: 4, value: ["2 ширхэг LAN гаргалттай."] },
      {
        id: 5,
        value: ["Хэрэглээний төлбөр 210.000 (нэг цэгт), 250.000 (олон цэг)"],
      },
      { id: 6, value: ["Дата хязгааргүй, хэрэглэхгүй сараа зогсоож болно."] },
      { id: 7, value: ["Хурд: 200-400mbps."] },
      { id: 8, value: ["6 сарын баталгаа."] },
      { id: 9, value: ["Идэвхжүүлэлт холболтын зөвлөгөө."] },
      { id: 10, value: ["Баталгаат засвар үйлчилгээ."] },
    ],
    accessories: [
      "12V залгуур (3м)",
      "Баттерэйнд холбогч",
      "220V залгуур (2м)",
      "Зурагдалтаас хамгаалах хуулга (8ш)",
      "Төмөр суурь",
      "Хэрэгслүүдийн уут",
    ],
  },
  {
    id: 3,
    name: "Аяллын Явдаг Станц (All in one)",
    img: stants,
    price: 5000000,
    img1: u3,
    img2: u4,
    video: "vlji7W7dOvY?si=xPDDjigE3pl3EeIj",
    headerText:
      "Аяллын кампер машины цахилгаан хангамжийн шийдлийг хялбаршуулан нэг хайрагт багтаасан Монгол инженерийн бүтээл. Аяллын замд машинаас 5 цагт бүрэн цэнэгээ авна.",
    descMain:
      "Аяллын кампер машины цахилгаан хангамжийн шийдлийг хялбаршуулан нэг хайрагт багтаасан Монгол инженерийн бүтээл. Аяллын замд машинаас 5 цагт бүрэн цэнэгээ авна.",
    descriptionObject: [
      { id: 1, value: ["Цэнэгийн үзүүлэлт: 2300Wh 180Аh 12.8V"] },
      { id: 2, value: ["Баттерэй төрөл: LiFePo4 (Lithium iron phosphate)"] },
      { id: 3, value: ["Цэнэг авах насжилт: 4000 удаа (10+жил)"] },
      { id: 4, value: ["Аюулын зэрэг: Баттерэй төрөлдөө хамгийн аюулгүй"] },
      {
        id: 5,
        value: [
          "Хяналт, мэдээлэл:: Bluetooth Аpp iOS болон Android. 20м хүртэл зайнаас холбогдоно.",
        ],
      },
      {
        id: 6,
        value: ["Гаралтын портууд:"],
        subValue: [
          "  AC 220V 400W",
          "  USB x3",
          "  Type C PD x1",
          "  Cigar x2",
          "  Anderson 50A x4, 175A x1",
        ],
      },
      {
        id: 7,
        value: ["Оролтын портууд:"],
        subValue: [
          "  Машины генератороос цэнэглэх x1 (12V 40А Anderson)",
          "  220С AC to DC 12V цэнэглэх x4 (50A MAX Anderson)",
          "  Нарны хавтангаас цэнэглэх",
          "    Controlled 12V x4 (50A MAX Anderson)",
          "    Uncontrolled to 32V x1 (40A Anderson)",
        ],
      },
      { id: 8, value: ["Хэмжээ: W22.5 x L38 x H47cm"] },
      { id: 9, value: ["Жин: 20,5кг"] },
      { id: 10, value: ["Холболт суурилуулалт үнэ төлбөргүй"] },
      { id: 11, value: ["6 сарын баталгаа."] },
      { id: 12, value: ["Баталгаат засвар үйлчилгээ."] },
    ],

    accessories: [
      "220V цэнэглэгч 25А",
      "Машины генераторт холбогч кабель ",
      "Холболт суурилуулалт",
    ],
  },
  {
    id: 4,
    name: "Starlink Standard Kit (Gen3)",
    img: standard_gen3,
    price: 3500000,
    img1: u3,
    img2: u4,
    video: "kPy64byMCx4?si=pJFVTGVmu_CEwhF5",
    headerText: "Starlink-с гаргасан хамгийн сүүлийн төхөөрөмж",

    accessories: ["12V залгуур (3м)", "Хэрэгслүүдийн уут"],
    descMain:
      "Аяллын кампер машины цахилгаан хангамжийн шийдлийг хялбаршуулан нэг хайрагт багтаасан Монгол инженерийн бүтээл. Аяллын замд машинаас 5 цагт бүрэн цэнэгээ авна.",
    descriptionObject: [
      { id: 1, value: ["Цэнэгийн үзүүлэлт: 2300Wh 180Аh 12.8V"] },
      { id: 2, value: ["Баттерэй төрөл: LiFePo4 (Lithium iron phosphate)"] },
    ],
  },
  {
    id: 4,
    name: "Сүлжээ тэлэгч",
    img: outdoor_wifi6,
    price: 1000000,
    accessories: [
      "12V залгуур (3м)",
      "Баттерэйнд холбогч",
      "220V залгуур (2м)",
      "Зурагдалтаас хамгаалах хуулга (8ш)",
      "Хэрэгслүүдийн уут",
    ],
    img1: u3,
    img2: u4,
    video: "vlji7W7dOvY?si=xPDDjigE3pl3EeIj",
    descMain:
      "Аяллын кампер машины цахилгаан хангамжийн шийдлийг хялбаршуулан нэг хайрагт багтаасан Монгол инженерийн бүтээл. Аяллын замд машинаас 5 цагт бүрэн цэнэгээ авна.",
    descriptionObject: [
      { id: 1, value: ["Цэнэгийн үзүүлэлт: 2300Wh 180Аh 12.8V"] },
      { id: 2, value: ["Баттерэй төрөл: LiFePo4 (Lithium iron phosphate)"] },
      { id: 3, value: ["Цэнэг авах насжилт: 4000 удаа (10+жил)"] },
      { id: 4, value: ["Аюулын зэрэг: Баттерэй төрөлдөө хамгийн аюулгүй"] },
      {
        id: 5,
        value: [
          "Хяналт, мэдээлэл:: Bluetooth Аpp iOS болон Android. 20м хүртэл зайнаас холбогдоно.",
        ],
      },
      {
        id: 6,
        value: ["Гаралтын портууд:"],
        subValue: [
          "AC 220V 400W",
          "USB x3",
          "Type C PD x1",
          "Cigar x2",
          "Anderson 50A x4, 175A x1",
        ],
      },
      {
        id: 7,
        value: ["Оролтын портууд:"],
        subValue: [
          "Машины генератороос цэнэглэх x1 (12V 40А Anderson)",
          "220С AC to DC 12V цэнэглэх x4 (50A MAX Anderson)",
          "Нарны хавтангаас цэнэглэх",
          "  Controlled 12V x4 (50A MAX Anderson)",
          "  Uncontrolled to 32V x1 (40A Anderson)",
        ],
      },
      { id: 8, value: ["Хэмжээ: W22.5 x L38 x H47cm"] },
      { id: 9, value: ["Жин: 20,5кг"] },
      { id: 10, value: ["Холболт суурилуулалт үнэ төлбөргүй"] },
      { id: 11, value: ["6 сарын баталгаа."] },
      { id: 12, value: ["Баталгаат засвар үйлчилгээ."] },
    ],
  },
  {
    id: 4,
    name: "Сүлжээ тэлэгч",
    img: outdoor_wifi6,
    img1: u3,
    img2: u4,
    price: 1000000,
    accessories: [
      "12V залгуур (3м)",
      "Баттерэйнд холбогч",
      "220V залгуур (2м)",
      "Зурагдалтаас хамгаалах хуулга (8ш)",
      "Хэрэгслүүдийн уут",
    ],
    video: "kPy64byMCx4?si=pJFVTGVmu_CEwhF5",
    descMain:
      "Аяллын кампер машины цахилгаан хангамжийн шийдлийг хялбаршуулан нэг хайрагт багтаасан Монгол инженерийн бүтээл. Аяллын замд машинаас 5 цагт бүрэн цэнэгээ авна.",
    descriptionObject: [
      { id: 1, value: ["Цэнэгийн үзүүлэлт: 2300Wh 180Аh 12.8V"] },
      { id: 2, value: ["Баттерэй төрөл: LiFePo4 (Lithium iron phosphate)"] },
      { id: 3, value: ["Цэнэг авах насжилт: 4000 удаа (10+жил)"] },
      { id: 4, value: ["Аюулын зэрэг: Баттерэй төрөлдөө хамгийн аюулгүй"] },
      {
        id: 5,
        value: [
          "Хяналт, мэдээлэл:: Bluetooth Аpp iOS болон Android. 20м хүртэл зайнаас холбогдоно.",
        ],
      },
      {
        id: 6,
        value: ["Гаралтын портууд:"],
        subValue: [
          "AC 220V 400W",
          "USB x3",
          "Type C PD x1",
          "Cigar x2",
          "Anderson 50A x4, 175A x1",
        ],
      },
      {
        id: 7,
        value: ["Оролтын портууд:"],
        subValue: [
          "Машины генератороос цэнэглэх x1 (12V 40А Anderson)",
          "220С AC to DC 12V цэнэглэх x4 (50A MAX Anderson)",
          "Нарны хавтангаас цэнэглэх",
          "  Controlled 12V x4 (50A MAX Anderson)",
          "  Uncontrolled to 32V x1 (40A Anderson)",
        ],
      },
      { id: 8, value: ["Хэмжээ: W22.5 x L38 x H47cm"] },
      { id: 9, value: ["Жин: 20,5кг"] },
      { id: 10, value: ["Холболт суурилуулалт үнэ төлбөргүй"] },
      { id: 11, value: ["6 сарын баталгаа."] },
      { id: 12, value: ["Баталгаат засвар үйлчилгээ."] },
    ],
  },
  {
    id: 5,
    name: "Starlink Standard (Gen3)",
    img: gen2,
    img1: u3,
    img2: u4,
    price: 4500000,
    headerText:
      "Монгол орны байгаль цаг агаар, замын нөхцөл, аврагчид, аялагчид, малчдад зориулан өөрчилж хялбаршуулсан Старлинкийн сансрын интернэтийн хөдөлгөөнт төхөөрөмж Starlink Nomad.",

    accessories: [
      "12V залгуур (3м)",
      "Баттерэйнд холбогч",
      "220V залгуур (2м)",
      "Зурагдалтаас хамгаалах хуулга (8ш)",
      "Хэрэгслүүдийн уут",
    ],
  },
  {
    id: 7,
    name: "Starlink Nomad - Явдаг интернэт (Gen 2)",
    img: gen2,
    img1: u3,
    img2: u4,
    price: 6500000,
    video: "xd7BpJkJdDQ?si=sTFbz_Ldf7nlMWms",
    headerText:
      "Монгол орны байгаль цаг агаар, замын нөхцөл, аврагчид, аялагчид, малчдад зориулан өөрчилж хялбаршуулсан Старлинкийн сансрын интернэтийн хөдөлгөөнт төхөөрөмж Starlink Nomad.",
    accessories: [
      "12V залгуур (3м)",
      "Баттерэйнд холбогч",
      "220V залгуур (2м)",
      "Зурагдалтаас хамгаалах хуулга (8ш)",
      "Төмөр суурь",
      "Хэрэгслүүдийн уут",
    ],
    descMain:
      "Starlink Nomad Gen2 нь манай анхны гаргасан хөдөлгөөнт загвар юм. ",
    descriptionObject: [
      {
        id: 1,
        value: [
          "Хэмжээний хувьд жижиг, жин бага авсаархан байдлаар давуу талтай",
        ],
      },
      {
        id: 2,
        value: ["Gen2 -ын загварууд:"],
        subValue: [
          "Стандарт буюу WiFi 5 утасгүй сүлжээ цацагч LAN гаргалтгүй загвар",
          "Cайжруулсан буюу WiFi 6 утасгүй сүлжээ цацагч, 1ш LAN гаргалттай загвар",
        ],
      },
      {
        id: 3,
        value: ["Хэрэглээний төлбөр 210.000 (нэг цэгт), 250.000 (олон цэг)"],
      },
      { id: 4, value: ["Дата хязгааргүй, хэрэглэхгүй сараа зогсоож болно."] },
      { id: 5, value: ["Хурд: 200-400mbps."] },
      { id: 6, value: ["Цахилгаан зарцуулалт: 60Wh (дундаж)"] },
      { id: 7, value: ["6 сарын баталгаа."] },
      { id: 8, value: ["Идэвхжүүлэлт холболтын зөвлөгөө."] },
      { id: 9, value: ["Баталгаат засвар үйлчилгээ."] },
    ],
  },
  {
    id: 8,
    name: "Аяллын Явдаг Станц (Суурилуулдаг)",
    img: sbattery,
    img1: u3,
    img2: u4,
    video: "zV4FFcjwGTc?si=eEShtCknPbhDtgNe",
    price: 4000000,
    headerText:
      "Аяллын кампер машины цахилгаан хангамжийн шийдлийг хялбаршуулан энгийн машинд тавих боломжтой болгосон хувилбар. Аяллын замд машинаас 4 цагт бүрэн цэнэгээ авна.",
    accessories: [
      "Машины генератороос цэнэглэгч",
      "Машины генераторт холбогч кабель",
      "220V цэнэглэгч ",
      "Сigar x2 салаалагч",
      "12 to 220v 400W инвертер",
      "Аnderson залгуур x4",
      "Холболт суурилуулалт",
    ],
    descMain:
      "Өөрийгөө халаах функц: Баттерей -5C хүртэл хөрөх үед гаднаас авч буй тэжээл зөвхөн халаагч ялтсыг тэжээж баттерейны температурыг +5С хүртэл халаасны дараа баттерейруу цэнэг шахаж эхэлнэ. ",
    descriptionObject: [
      { id: 1, value: ["Цэнэгийн үзүүлэлт: 1971Wh 154Аh 12.8V"] },
      { id: 2, value: ["Өөрийгөө халаах функ: -5C хүртэл хөрөх үед"] },
      { id: 3, value: ["Баттерэй төрөл: LiFePo4 (Lithium iron phosphate)"] },
      { id: 4, value: ["Цэнэг авах насжилт: 4000 удаа (10+жил)"] },
      { id: 5, value: ["Аюулын зэрэг: Баттерэй төрөлдөө хамгийн аюулгүй"] },
      {
        id: 6,
        value: [
          "Хяналт, мэдээлэл: Bluetooth Аpp iOS болон Android. 20м хүртэл зайнаас холбогдоно.",
        ],
      },
      {
        id: 7,
        value: ["Гаралтын портууд:"],
        subValue: [
          "Anderson 50A, 175A- ∞ салаалах",
          "Cigar x1",
          "DC Power 5.5 x 2.1mm x2",
        ],
      },
      {
        id: 8,
        value: ["Оролтын портууд:"],
        subValue: ["Anderson 50A, 175A- ∞ салаалах."],
      },
      { id: 9, value: ["Хэмжээ: W12 x L40 x H18cm"] },
      { id: 10, value: ["Жин: 16кг"] },
      { id: 11, value: ["Холболт суурилуулалт үнэ төлбөргүй"] },
      { id: 12, value: ["6 сарын баталгаа."] },
      { id: 13, value: ["Баталгаат засвар үйлчилгээ."] },
    ],
  },

  {
    id: 8,
    name: "Аяллын Явдаг Станц (Суурилуулдаг)",
    img: sbattery,
    img1: u3,
    img2: u4,
    price: 4000000,
    headerText:
      "Монгол орны байгаль цаг агаар, замын нөхцөл, аврагчид, аялагчид, малчдад зориулан өөрчилж хялбаршуулсан Старлинкийн сансрын интернэтийн хөдөлгөөнт төхөөрөмж Starlink Nomad.",
    accessories: [
      "220V залгуур (2м)",
      "Зурагдалтаас хамгаалах хуулга (8ш)",
      "Хэрэгслүүдийн уут",
    ],
    descMain:
      "Starlink Nomad Gen2 нь манай анхны гаргасан хөдөлгөөнт загвар юм. ",
    descriptionObject: [
      { id: 1, value: ["Цэнэгийн үзүүлэлт: 1971Wh 154Аh 12.8V"] },
    ],
  },
];
export const products = [
  {
    id: 1,
    name: "Starlink Nomad - Явдаг интернэт (Gen 3)",
    img: gen3,
    img1: u3,
    img2: u4,
    video: "xd7BpJkJdDQ?si=W138WdCwQmPOmmxh",
    price: 6500000,
    headerText:
      "Монгол орны байгаль цаг агаар, замын нөхцөл, аврагчид, аялагчид, малчдад зориулан өөрчилж хялбаршуулсан Старлинкийн сансрын интернэтийн хөдөлгөөнт төхөөрөмж Starlink Nomad.",
    descMain:
      "Starlink Nomad Gen3 нь манай хамгийн сүүлд гаргасан хөдөлгөөнт загвар юм.",
    descriptionObject: [
      {
        id: 1,
        value: [
          "Өмнөх Gen2 загвараас 30%-р интернэтийн хурд, сүлжээний тогтвортой байдлаар илүү.",
        ],
      },
      { id: 2, value: ["Gen2 -c 5 дахин хурдан сүлжээнд холбогдоно."] },
      {
        id: 3,
        value: [
          "Gen2 нь WiFi 5 байдаг бол Gen3-ын WiFi6 цацагч илүү өргөн талбайд сүлжээ цацна",
        ],
      },
      { id: 4, value: ["2 ширхэг LAN гаргалттай."] },
      {
        id: 5,
        value: ["Хэрэглээний төлбөр 210.000 (нэг цэгт), 250.000 (олон цэг)"],
      },
      { id: 6, value: ["Дата хязгааргүй, хэрэглэхгүй сараа зогсоож болно."] },
      { id: 7, value: ["Хурд: 200-400mbps."] },
      { id: 8, value: ["6 сарын баталгаа."] },
      { id: 9, value: ["Идэвхжүүлэлт холболтын зөвлөгөө."] },
      { id: 10, value: ["Баталгаат засвар үйлчилгээ."] },
    ],
    accessories: [
      "12V залгуур (3м)",
      "Баттерэйнд холбогч",
      "220V залгуур (2м)",
      "Зурагдалтаас хамгаалах хуулга (8ш)",
      "Төмөр суурь",
      "Хэрэгслүүдийн уут",
    ],
    category: 1,
    mainProduct: 1,
    batteryType: 3,
    additional: [7, 2, 8, 10, 11, 99],
    additionalBuyDetail: [6, 7, 2, 10, 11, 12, 4, 8],
    mostBuy: 9,
    addNew: 1,
  },
  {
    id: 2,
    name: "Аяллын Явдаг Станц (All in one)",
    img: stants,
    price: 4500000,
    img1: u3,
    img2: u4,
    video: "vlji7W7dOvY?si=xPDDjigE3pl3EeIj",
    headerText:
      "Аяллын кампер машины цахилгаан хангамжийн шийдлийг хялбаршуулан нэг хайрагт багтаасан Монгол инженерийн бүтээл. Аяллын замд машинаас 5 цагт бүрэн цэнэгээ авна.",
    descMain:
      "Аяллын кампер машины цахилгаан хангамжийн шийдлийг хялбаршуулан нэг хайрагт багтаасан Монгол инженерийн бүтээл. Аяллын замд машинаас 5 цагт бүрэн цэнэгээ авна.",
    descriptionObject: [
      { id: 1, value: ["Цэнэгийн үзүүлэлт: 2300Wh 180Аh 12.8V"] },
      { id: 2, value: ["Баттерэй төрөл: LiFePo4 (Lithium iron phosphate)"] },
      { id: 3, value: ["Цэнэг авах насжилт: 4000 удаа (10+жил)"] },
      { id: 4, value: ["Аюулын зэрэг: Баттерэй төрөлдөө хамгийн аюулгүй"] },
      {
        id: 5,
        value: [
          "Хяналт, мэдээлэл:: Bluetooth Аpp iOS болон Android. 20м хүртэл зайнаас холбогдоно.",
        ],
      },
      {
        id: 6,
        value: ["Гаралтын портууд:"],
        subValue: [
          "  AC 220V 400W",
          "  USB x3",
          "  Type C PD x1",
          "  Cigar x2",
          "  Anderson 50A x4, 175A x1",
        ],
      },
      {
        id: 7,
        value: ["Оролтын портууд:"],
        subValue: [
          "  Машины генератороос цэнэглэх x1 (12V 40А Anderson)",
          "  220С AC to DC 12V цэнэглэх x4 (50A MAX Anderson)",
          "  Нарны хавтангаас цэнэглэх",
          "    Controlled 12V x4 (50A MAX Anderson)",
          "    Uncontrolled to 32V x1 (40A Anderson)",
        ],
      },
      { id: 8, value: ["Хэмжээ: W22.5 x L38 x H47cm"] },
      { id: 9, value: ["Жин: 20,5кг"] },
      { id: 10, value: ["Холболт суурилуулалт үнэ төлбөргүй"] },
      { id: 11, value: ["6 сарын баталгаа."] },
      { id: 12, value: ["Баталгаат засвар үйлчилгээ."] },
    ],

    accessories: [
      "220V цэнэглэгч 25А",
      "Машины генераторт холбогч кабель ",
      "Холболт суурилуулалт",
    ],
    category: 4,
    mainProduct: 1,
    batteryType: 2,
    additional: [1, 2, 3, 4, 99],
    additionalBuyDetail: [1, 5, 15],
    mostBuy: 8,
    addNew: 2,
  },
  {
    id: 3,
    name: "Starlink Standard Kit (Gen3)",
    img: standard_gen3,
    price: 3500000,
    img1: gen3_u1,
    img2: gen3_u2,
    video: "kPy64byMCx4?si=pJFVTGVmu_CEwhF5",
    headerText: "Starlink-с гаргасан хамгийн сүүлийн төхөөрөмж",

    accessories: [],
    descMain:
      "Аяллын кампер машины цахилгаан хангамжийн шийдлийг хялбаршуулан нэг хайрагт багтаасан Монгол инженерийн бүтээл. Аяллын замд машинаас 5 цагт бүрэн цэнэгээ авна.",
    descriptionObject: [
      { id: 1, value: ["Антенна:  Electronic Phased Array"] },
      { id: 2, value: ["Харах талбайн хэмжээ:  110°"] },
      { id: 3, value: ["Харах чиглэл:  Програмын тусламжтай гар тохиргоо"] },
      { id: 4, value: ["Тоос, усны хамгаалалт:  IP67 Type 4      "] },
      { id: 5, value: ["Ажиллах хэм:  -30°C to 50°C      "] },
      { id: 6, value: ["Салхины хурд:  Ажиллах явца: 96 км/ц+"] },
      { id: 7, value: ["Цас хайлуулах чадвар:  40 мм/цаг"] },
      { id: 8, value: ["Цахилгаан зарцуулалт:  75 – 100Wh"] },
      {
        id: 9,
        value: ["Жин:  Дан Антенна 2.9 kg, бусад хэрэгслийн хамт  5.02кг"],
      },
      {
        id: 10,
        value: [
          "Wi-Fi технологи:  WiFi 6. 802.11 a/b/g/n/ac/ax. Tri Band 4 x 4 MU-MIMO",
        ],
      },
      { id: 11, value: ["Цацах хүрээ:  297 m² хүртэл талбайд"] },
      { id: 12, value: ["LAN порт:  2ширхэг"] },
      { id: 13, value: ["Төхөөрөмж холбох чадвар:  235 хүртэл"] },
      { id: 14, value: ["Цахилгаан:  100-240V ~ 2.5A 50 - 60 Hz"] },
    ],
    category: 2,
    mainProduct: 1,
    batteryType: 220,
    additional: [1, 2, 3, 4, 99],
    additionalBuyDetail: [27, 28, 29, 30, 31, 32, 19, 10, 11, 12],
    mostBuy: 5,
    addNew: 5,
  },
  {
    id: 4,
    name: "Сүлжээ тэлэгч",
    img: outdoor_wifi6,
    price: 1000000,
    accessories: [""],
    img1: u3,
    img2: u4,
    video: "vlji7W7dOvY?si=xPDDjigE3pl3EeIj",
    descMain: "Үндсэн текст",
    descriptionObject: [{ id: 1, value: [""] }],
    category: 3,
    mainProduct: 1,
    batteryType: 3,
    additional: [1, 2, 3, 4, 99],
    additionalBuyDetail: [1, 3, 5],
    mostBuy: 3,
    addNew: 3,
  },
  {
    id: 5,
    name: "Starlink Nomad Gen2",
    img: gen2,
    img1: u3,
    img2: u4,
    price: 6500000,
    video: "xd7BpJkJdDQ?si=sTFbz_Ldf7nlMWms",
    headerText:
      "Монгол орны байгаль цаг агаар, замын нөхцөл, аврагчид, аялагчид, малчдад зориулан өөрчилж хялбаршуулсан Старлинкийн сансрын интернэтийн хөдөлгөөнт төхөөрөмж Starlink Nomad.",
    accessories: [
      "12V залгуур (3м)",
      "Баттерэйнд холбогч",
      "220V залгуур (2м)",
      "Зурагдалтаас хамгаалах хуулга (8ш)",
      "Төмөр суурь",
      "Хэрэгслүүдийн уут",
    ],
    descMain:
      "Starlink Nomad Gen2 нь манай анхны гаргасан хөдөлгөөнт загвар юм. ",
    descriptionObject: [
      {
        id: 1,
        value: [
          "Хэмжээний хувьд жижиг, жин бага авсаархан байдлаар давуу талтай",
        ],
      },
      {
        id: 2,
        value: ["Gen2 -ын загварууд:"],
        subValue: [
          "Стандарт буюу WiFi 5 утасгүй сүлжээ цацагч LAN гаргалтгүй загвар",
          "Cайжруулсан буюу WiFi 6 утасгүй сүлжээ цацагч, 1ш LAN гаргалттай загвар",
        ],
      },
      {
        id: 3,
        value: ["Хэрэглээний төлбөр 210.000 (нэг цэгт), 250.000 (олон цэг)"],
      },
      { id: 4, value: ["Дата хязгааргүй, хэрэглэхгүй сараа зогсоож болно."] },
      { id: 5, value: ["Хурд: 200-400mbps."] },
      { id: 6, value: ["Цахилгаан зарцуулалт: 60Wh (дундаж)"] },
      { id: 7, value: ["6 сарын баталгаа."] },
      { id: 8, value: ["Идэвхжүүлэлт холболтын зөвлөгөө."] },
      { id: 9, value: ["Баталгаат засвар үйлчилгээ."] },
    ],
    category: 1,
    mainProduct: 1,
    batteryType: 3,
    additional: [
      8, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27,
      28, 29, 30, 31, 32, 7,
    ],

    additionalBuyDetail: [6, 33, 26, 2, 10, 11, 12, 4, 31],
    mostBuy: 3,
    addNew: 3,
  },
  {
    id: 6,
    name: "Аяллын Явдаг Станц (Суурилуулдаг)",
    img: sbattery,
    img1: batterysmall,
    img2: batterysmall1,
    video: "zV4FFcjwGTc?si=eEShtCknPbhDtgNe",
    price: 3500000,
    headerText:
      "Аяллын кампер машины цахилгаан хангамжийн шийдлийг хялбаршуулан энгийн машинд тавих боломжтой болгосон хувилбар. Аяллын замд машинаас 4 цагт бүрэн цэнэгээ авна.",
    accessories: [
      "Машины генератороос цэнэглэгч",
      "Машины генераторт холбогч кабель",
      "220V цэнэглэгч ",
      "Сigar x2 салаалагч",
      "12 to 220v 400W инвертер",
      "Аnderson залгуур x4",
      "Холболт суурилуулалт",
    ],
    descMain:
      "Өөрийгөө халаах функц: Баттерей -5C хүртэл хөрөх үед гаднаас авч буй тэжээл зөвхөн халаагч ялтсыг тэжээж баттерейны температурыг +5С хүртэл халаасны дараа баттерейруу цэнэг шахаж эхэлнэ. ",
    descriptionObject: [
      { id: 1, value: ["Цэнэгийн үзүүлэлт: 1971Wh 154Аh 12.8V"] },
      { id: 2, value: ["Өөрийгөө халаах функ: -5C хүртэл хөрөх үед"] },
      { id: 3, value: ["Баттерэй төрөл: LiFePo4 (Lithium iron phosphate)"] },
      { id: 4, value: ["Цэнэг авах насжилт: 4000 удаа (10+жил)"] },
      { id: 5, value: ["Аюулын зэрэг: Баттерэй төрөлдөө хамгийн аюулгүй"] },
      {
        id: 6,
        value: [
          "Хяналт, мэдээлэл: Bluetooth Аpp iOS болон Android. 20м хүртэл зайнаас холбогдоно.",
        ],
      },
      {
        id: 7,
        value: ["Гаралтын портууд:"],
        subValue: [
          "Anderson 50A, 175A- ∞ салаалах",
          "Cigar x1",
          "DC Power 5.5 x 2.1mm x2",
        ],
      },
      {
        id: 8,
        value: ["Оролтын портууд:"],
        subValue: ["Anderson 50A, 175A- ∞ салаалах."],
      },
      { id: 9, value: ["Хэмжээ: W12 x L40 x H18cm"] },
      { id: 10, value: ["Жин: 16кг"] },
      { id: 11, value: ["Холболт суурилуулалт үнэ төлбөргүй"] },
      { id: 12, value: ["6 сарын баталгаа."] },
      { id: 13, value: ["Баталгаат засвар үйлчилгээ."] },
    ],
    category: 4,
    mainProduct: 2,
    batteryType: 3,
    additional: [1, 2, 3, 4],
    additionalBuyDetail: [1, 5, 15, 19],
    mostBuy: 3,
    addNew: 3,
  },
  {
    id: 7,
    name: "Starlink Nomad цүнх Gen3",
    img: starlink_carry_bag,
    img1: batterysmall,
    img2: gen2_9,
    video: "zV4FFcjwGTc?si=eEShtCknPbhDtgNe",
    price: 100000,
    headerText: "Starlink Nomad цүнх Gen3",
    accessories: [""],
    descMain: "Starlink Nomad цүнх Gen3 ",
    descriptionObject: [{ id: 1, value: [""] }],
    category: 4,
    mainProduct: 2,
    batteryType: 3,
    additional: [1],
    additionalBuyDetail: [1],
    mostBuy: 3,
    addNew: 3,
  },
  {
    id: 8,
    name: "Машины ачаанд бэхлэх ган суурь (Gen 3)",
    img: gen3_achaa_suuri,
    img1: u3,
    img2: u4,
    video: "zV4FFcjwGTc?si=eEShtCknPbhDtgNe",
    price: 150000,
    headerText: "Машины ачаанд бэхлэх ган суурь (Gen 3)",
    accessories: [""],
    descMain: "Машины ачаанд бэхлэх ган суурь (Gen 3) ",
    descriptionObject: [{ id: 1, value: [""] }],
    category: 4,
    mainProduct: 2,
    batteryType: 3,
    additional: [1],
    additionalBuyDetail: [1],
    mostBuy: 3,
    addNew: 3,
  },
  {
    id: 9,
    name: "WiFi5 гадна зориулалтын Access point 4 omni antena",
    img: outdoor_wifi6_small,
    img1: batterysmall,
    img2: batterysmall1,
    video: "zV4FFcjwGTc?si=eEShtCknPbhDtgNe",
    price: 600000,
    headerText: "WiFi5 гадна зориулалтын Access point 4 omni antena",
    accessories: ["Машины генератороос цэнэглэгч"],
    descMain: "WiFi5 гадна зориулалтын Access point 4 omni antena ",
    descriptionObject: [{ id: 1, value: [""] }],
    category: 4,
    mainProduct: 2,
    batteryType: 3,
    additional: [1, 2, 3, 4],
    additionalBuyDetail: [1, 5, 3],
    mostBuy: 3,
    addNew: 3,
  },

  {
    id: 10,
    name: "WiFi5 гадна зориулалтын Access point 2 omni antena",
    img: outdoor_wifi_small,
    img1: batterysmall,
    img2: batterysmall1,
    video: "zV4FFcjwGTc?si=eEShtCknPbhDtgNe",
    price: 400000,
    headerText: "WiFi5 гадна зориулалтын Access point 2 omni antena",
    accessories: [""],
    descMain: "WiFi5 гадна зориулалтын Access point 2 omni antena",
    descriptionObject: [{ id: 1, value: [""] }],
    category: 4,
    mainProduct: 2,
    batteryType: 3,
    additional: [1, 2, 3, 4],
    additionalBuyDetail: [1, 5, 3],
    mostBuy: 3,
    addNew: 3,
  },
  {
    id: 11,
    name: "WiFi6 дотор зориулалтын Access point",
    img: indoor_wifi6,
    img1: batterysmall,
    img2: batterysmall1,
    video: "zV4FFcjwGTc?si=eEShtCknPbhDtgNe",
    price: 400000,
    headerText: "WiFi6 дотор зориулалтын Access point",
    accessories: [""],
    descMain: "WiFi6 дотор зориулалтын Access point",
    descriptionObject: [{ id: 1, value: [""] }],
    category: 4,
    mainProduct: 2,
    batteryType: 3,
    additional: [1, 2, 3, 4],
    additionalBuyDetail: [1, 5, 3],
    mostBuy: 3,
    addNew: 3,
  },
  {
    id: 12,
    name: "Жанамнаас цэнэглэгч кабль",
    img: car_charger_cable,
    img1: batterysmall,
    img2: batterysmall1,
    video: "zV4FFcjwGTc?si=eEShtCknPbhDtgNe",
    price: 150000,
    headerText: "WiFi6 дотор зориулалтын Access point",
    accessories: [""],
    descMain: "WiFi6 дотор зориулалтын Access point",
    descriptionObject: [{ id: 1, value: [""] }],
    category: 4,
    mainProduct: 2,
    batteryType: 3,
    additional: [1, 2, 3, 4],
    additionalBuyDetail: [2, 6],
  },
  {
    id: 13,
    name: "Нарны панель 200Watt",
    img: solar_panel,
    img1: batterysmall,
    img2: batterysmall1,
    video: "zV4FFcjwGTc?si=eEShtCknPbhDtgNe",
    price: 1000000,
    headerText: "Нарны панель 200Watt",
    accessories: ["Машины генератороос цэнэглэгч"],
    descMain: "WiFi6 дотор зориулалтын Access point",
    descriptionObject: [
      { id: 1, value: ["Цэнэгийн үзүүлэлт: 1971Wh 154Аh 12.8V"] },
    ],
    category: 4,
    mainProduct: 2,
    batteryType: 3,
    additional: [1, 2, 3, 4],
    mostBuy: 3,
    addNew: 3,
  },
  {
    id: 14,
    name: "Зөөврийн нарны панель 300Watt",
    img: solar_panel_unfolded,
    img1: batterysmall,
    img2: batterysmall1,
    video: "zV4FFcjwGTc?si=eEShtCknPbhDtgNe",
    price: 1300000,
    headerText: "Зөөврийн нарны панель 300Watt",
    accessories: [""],
    descMain: "Зөөврийн нарны панель 300Watt",
    descriptionObject: [{ id: 1, value: [""] }],
    category: 4,
    mainProduct: 2,
    batteryType: 3,
    additional: [1, 2, 3, 4],
    additionalBuyDetail: [2, 6],
    mostBuy: 3,
    addNew: 3,
  },
  {
    id: 15,
    name: "220V-c цэнэглэгч",
    img: charger_220,
    img1: batterysmall,
    img2: batterysmall1,
    video: "zV4FFcjwGTc?si=eEShtCknPbhDtgNe",
    price: 250000,
    headerText: "220V-c цэнэглэгч",
    accessories: [""],
    descMain: "",
    descriptionObject: [{ id: 1, value: [""] }],
    category: 4,
    mainProduct: 2,
    batteryType: 3,
    additional: [1, 2, 3, 4],
    additionalBuyDetail: [2, 6],
    mostBuy: 3,
    addNew: 3,
  },
  {
    id: 16,
    name: "Cigar салаалагч",
    img: salaalagch_12v,
    img1: batterysmall,
    img2: batterysmall1,
    video: "zV4FFcjwGTc?si=eEShtCknPbhDtgNe",
    price: 50000,
    headerText: "Cigar салаалагч",
    accessories: [""],
    descMain: "",
    descriptionObject: [{ id: 1, value: [""] }],
    category: 4,
    mainProduct: 2,
    batteryType: 3,
    additional: [1, 2, 3, 4],
    additionalBuyDetail: [2, 6],
    mostBuy: 3,
    addNew: 3,
  },
  {
    id: 17,
    name: "12V залгуур (Starlink Nomad)",
    img: zalguur_12V,
    img1: batterysmall,
    img2: batterysmall1,
    video: "zV4FFcjwGTc?si=eEShtCknPbhDtgNe",
    price: 80000,
    headerText: "12V залгуур (Starlink Nomad)",
    accessories: [""],
    descMain: "",
    descriptionObject: [{ id: 1, value: [""] }],
    category: 4,
    mainProduct: 2,
    batteryType: 3,
    additional: [1, 2, 3, 4],
    additionalBuyDetail: [1, 5],
    mostBuy: 3,
    addNew: 3,
  },

  {
    id: 18,
    name: "12V to 220V AC inverter 600W",
    img: inverter_220,
    img1: batterysmall,
    img2: batterysmall1,
    video: "zV4FFcjwGTc?si=eEShtCknPbhDtgNe",
    price: 350000,
    headerText: "Pure sine wave 12V to 220V AC inverter 600W",
    accessories: [""],
    descMain: "",
    descriptionObject: [{ id: 1, value: ["12V-с 220V гаргах өндөр чанартай хувиргуур"] }],
    category: 4,
    mainProduct: 2,
    batteryType: 3,
    additional: [1, 2, 3, 4],
    additionalBuyDetail: [6, 3],
    mostBuy: 3,
    addNew: 3,
  },
  {
    id: 19,
    name: "220V залгуур (Starlink Nomad)",
    img: zalguur_220,
    img1: batterysmall,
    img2: batterysmall1,
    video: "zV4FFcjwGTc?si=eEShtCknPbhDtgNe",
    price: 50000,
    headerText: "220V залгуур (Starlink Nomad)",
    accessories: [""],
    descMain: "220V залгуур (Starlink Nomad)",
    descriptionObject: [{ id: 1, value: ["220V залгуур (Starlink Nomad)"] }],
    category: 4,
    mainProduct: 2,
    batteryType: 3,
    additional: [1, 2, 3, 4],
    additionalBuyDetail: [1, 5],
    mostBuy: 3,
    addNew: 3,
  },
  {
    id: 20,
    name: "Баттерэй чимхдэг залгуур",
    img: acc_zalguur,
    img1: batterysmall,
    img2: batterysmall1,
    video: "zV4FFcjwGTc?si=eEShtCknPbhDtgNe",
    price: 20000,
    headerText: "АСС залгуур",
    accessories: [""],
    descMain: "АСС залгуур",
    descriptionObject: [{ id: 1, value: [""] }],
    category: 4,
    mainProduct: 2,
    batteryType: 3,
    additional: [1, 2, 3, 4],
    additionalBuyDetail: [1, 5],
    mostBuy: 3,
    addNew: 3,
  },
  {
    id: 21,
    name: "Соронзон суурь (1ш)",
    img: soronzon_suuri,
    img1: batterysmall,
    img2: batterysmall1,
    video: "zV4FFcjwGTc?si=eEShtCknPbhDtgNe",
    price: 20000,
    headerText: "Соронзон суурь (1ш)",
    accessories: [""],
    descMain: "Соронзон суурь (1ш)",
    descriptionObject: [{ id: 1, value: [""] }],
    category: 4,
    mainProduct: 2,
    batteryType: 3,
    additional: [1, 2, 3, 4],
    additionalBuyDetail: [1, 5],
    mostBuy: 3,
    addNew: 3,
  },
  {
    id: 22,
    name: "Anderson залгуур (50A)",
    img: zalguur,
    img1: batterysmall,
    img2: batterysmall1,
    video: "zV4FFcjwGTc?si=eEShtCknPbhDtgNe",
    price: 10000,
    headerText: "Anderson залгуур (50A)",
    accessories: [""],
    descMain: "Anderson залгуур (50A)",
    descriptionObject: [{ id: 1, value: [""] }],
    category: 4,
    mainProduct: 2,
    batteryType: 3,
    additional: [1, 2, 3, 4],
    additionalBuyDetail: [2, 6],
    mostBuy: 3,
    addNew: 3,
  },
  {
    id: 23,
    name: "Anderson залгуур (125A)",
    img: zalguur,
    img1: batterysmall,
    img2: batterysmall1,
    video: "zV4FFcjwGTc?si=eEShtCknPbhDtgNe",
    price: 20000,
    headerText: "Anderson залгуур (125A)",
    accessories: [""],
    descMain: "Anderson залгуур (125A)",
    descriptionObject: [{ id: 1, value: [""] }],
    category: 4,
    mainProduct: 2,
    batteryType: 3,
    additional: [1, 2, 3, 4],
    additionalBuyDetail: [2],
    mostBuy: 3,
    addNew: 3,
  },
  {
    id: 24,
    name: "Anderson залгуур(50A 3 port)",
    img: zalguur,
    img1: batterysmall,
    img2: batterysmall1,
    video: "zV4FFcjwGTc?si=eEShtCknPbhDtgNe",
    price: 20000,
    headerText: "Anderson залгуур(50A 3 port)",
    accessories: [""],
    descMain: "Anderson залгуур(50A 3 port)",
    descriptionObject: [{ id: 1, value: [""] }],
    category: 4,
    mainProduct: 2,
    batteryType: 3,
    additional: [1, 2, 3, 4],
    additionalBuyDetail: [2],
    mostBuy: 3,
    addNew: 3,
  },

  {
    id: 25,
    name: "Машины ачаанд бэхлэх ган суурь (Gen2)",
    img: gen2suuri,
    img1: u3,
    img2: u4,
    video: "zV4FFcjwGTc?si=eEShtCknPbhDtgNe",
    price: 150000,
    headerText: "Машины ачаанд бэхлэх ган суурь (Gen 2)",
    accessories: ["Машины генератороос цэнэглэгч"],
    descMain: "Машины ачаанд бэхлэх ган суурь (Gen 2) ",
    descriptionObject: [
      { id: 1, value: ["Цэнэгийн үзүүлэлт: 1971Wh 154Аh 12.8V"] },
    ],
    category: 4,
    mainProduct: 2,
    batteryType: 3,
    additional: [5],
    additionalBuyDetail: [5],
    mostBuy: 3,
    addNew: 3,
  },
  {
    id: 26,
    name: "Starlink Standard Kit (Gen3)-ын тохируулгатай ган суурь (толгой хэсэг)",
    img: tolgoi,
    img1: gen3_u1,
    img2: gen3_u2,
    video: "zV4FFcjwGTc?si=eEShtCknPbhDtgNe",
    price: 250000,
    headerText:
      "Starlink Standard Kit (Gen3)-ын тохируулгатай ган суурь (толгой хэсэг)",
    accessories: ["Машины генератороос цэнэглэгч"],
    descMain:
      "Starlink Standard Kit (Gen3)-ын тохируулгатай ган суурь (толгой хэсэг)",
    descriptionObject: [{ id: 1, value: "" }],
    category: 4,
    mainProduct: 2,
    batteryType: 3,
    additional: [31, 29, 30, 28, 3],
    additionalBuyDetail: [31, 29, 30, 27, 3],
    mostBuy: 3,
    addNew: 3,
  },
  {
    id: 27,
    name: "Starlink Standard Kit (Gen3)-ын тохируулгатай ган суурь (холбох турба)",
    img: holbohturba,
    img1: gen3_u1,
    img2: gen3_u2,
    video: "zV4FFcjwGTc?si=eEShtCknPbhDtgNe",
    price: 20000,
    headerText:
      "Starlink Standard Kit (Gen3)-ын тохируулгатай ган суурь (холбох турба)",
    accessories: [""],
    descMain:
      "Starlink Standard Kit (Gen3)-ын тохируулгатай ган суурь (холбох турба) ",
    descriptionObject: [{ id: 1, value: "" }],
    category: 4,
    mainProduct: 2,
    batteryType: 3,
    additional: [31, 29, 30, 27, 3],
    additionalBuyDetail: [31, 29, 30, 28, 3, 26],
    mostBuy: 3,
    addNew: 3,
  },
  {
    id: 28,
    name: "Starlink Standard Kit (Gen3)-ын тохируулгатай ган суурь (Булан 45°)",
    img: g45,
    img1: gen3_u1,
    img2: gen3_u2,
    video: "zV4FFcjwGTc?si=eEShtCknPbhDtgNe",
    price: 50000,
    headerText:
      "Starlink Standard Kit (Gen3)-ын тохируулгатай ган суурь (Булан 45°)",
    accessories: [""],
    descMain:
      "Starlink Standard Kit (Gen3)-ын тохируулгатай ган суурь (Булан 45°)",
    descriptionObject: [{ id: 1, value: "" }],
    category: 4,
    mainProduct: 2,
    batteryType: 3,
    additional: [31, 28, 30, 27, 3],
    additionalBuyDetail: [31, 29, 30, 27, 3, 26],
    mostBuy: 3,
    addNew: 3,
  },
  {
    id: 29,
    name: "Starlink Standard Kit (Gen3)-ын тохируулгатай ган суурь (Булан 90°)",
    img: g45,
    img1: gen3_u1,
    img2: gen3_u2,
    video: "zV4FFcjwGTc?si=eEShtCknPbhDtgNe",
    price: 50000,
    headerText:
      "Starlink Standard Kit (Gen3)-ын тохируулгатай ган суурь (Булан 90°)",
    accessories: [""],
    descMain:
      "Starlink Standard Kit (Gen3)-ын тохируулгатай ган суурь (Булан 90°)",
    descriptionObject: [{ id: 1, value: [""] }],
    category: 4,
    mainProduct: 2,
    batteryType: 3,
    additional: [31, 28, 29, 27, 3],
    additionalBuyDetail: [31, 28, 30, 27, 3, 26],
    mostBuy: 3,
    addNew: 3,
  },
  {
    id: 30,
    name: "Starlink Standard Kit (Gen3)-ын тохируулгатай ган суурь (Бэхлэх хэсэг)",
    img: behleh,
    img1: batterysmall,
    img2: batterysmall1,
    video: "zV4FFcjwGTc?si=eEShtCknPbhDtgNe",
    price: 70000,
    headerText: "Машины ачаанд бэхлэх ган суурь (Gen 3)",
    accessories: ["Машины генератороос цэнэглэгч"],
    descMain: "Машины ачаанд бэхлэх ган суурь (Gen 3) ",
    descriptionObject: [
      { id: 1, value: ["Цэнэгийн үзүүлэлт: 1971Wh 154Аh 12.8V"] },
    ],
    category: 4,
    mainProduct: 2,
    batteryType: 3,
    additional: [31, 28, 29, 27, 3],
    additionalBuyDetail: [31, 28, 29, 27, 3, 26],
    mostBuy: 3,
    addNew: 3,
  },
  {
    id: 31,
    name: "Starlink Nomad цүнх Gen2",
    img: starlink_carry_bag,
    img1: batterysmall,
    img2: batterysmall1,
    video: "zV4FFcjwGTc?si=eEShtCknPbhDtgNe",
    price: 100000,
    headerText: "Starlink Nomad цүнх Gen2",
    accessories: [""],
    descMain: "Starlink Nomad цүнх Gen2 ",
    descriptionObject: [{ id: 1, value: [""] }],
    category: 4,
    mainProduct: 2,
    batteryType: 3,
    additional: [1],
    additionalBuyDetail: [5],
    mostBuy: 3,
    addNew: 3,
  },
  {
    id: 32,
    name: "Цахилгаан тогтворжуулагч 500W",
    img: regulator,
    img1: regulator,
    img2: regulator2,
    video: "zV4FFcjwGTc?si=eEShtCknPbhDtgNe",
    price: 350000,
    headerText: "Цахилгаан тогтворжуулагч 500W, 180V ~ 260V",
    accessories: [""],
    descMain: "Цахилгаан тогтворжуулагч 500W, 180V ~ 260V",
    descriptionObject: [{ id: 1, value: ["Цахилгааны хэлбэлзэлтэй үед тогтвортой 220V гаргах төхөөрөмж"] }],
    category: 4,
    mainProduct: 2,
    batteryType: 3,
    additional: [1],
    additionalBuyDetail: [3],
    mostBuy: 3,
    addNew: 3,
  },

  {
    id: 99,
    name: "Бусад нэмэлт хэрэгслүүд",
    img: more,
    img1: more,
    img2: more,
    video: "zV4FFcjwGTc?si=eEShtCknPbhDtgNe",
    price: 20000,
    headerText: "WiFi6 дотор зориулалтын Access point",
    accessories: ["Машины генератороос цэнэглэгч"],
    descMain: "WiFi6 дотор зориулалтын Access point",
    descriptionObject: [
      { id: 1, value: ["Цэнэгийн үзүүлэлт: 1971Wh 154Аh 12.8V"] },
    ],
    category: 4,
    mainProduct: 2,
    batteryType: 3,
    additional: [1, 2, 3, 4],
  },
  {
    id: 9,
    name: "Зөөврийн нарны панель",
    img: solar_panel,
    img1: batterysmall,
    img2: batterysmall1,
    video: "zV4FFcjwGTc?si=eEShtCknPbhDtgNe",
    price: 400000,
    headerText: "Машины ачаанд бэхлэх ган суурь (Gen 3)",
    accessories: ["Машины генератороос цэнэглэгч"],
    descMain: "Машины ачаанд бэхлэх ган суурь (Gen 3) ",
    descriptionObject: [
      { id: 1, value: ["Цэнэгийн үзүүлэлт: 1971Wh 154Аh 12.8V"] },
    ],
    category: 4,
    mainProduct: 2,
    batteryType: 3,
    additional: [1, 2, 3, 4],
    additionalBuyDetail: [1, 2, 3, 4],
    mostBuy: 3,
    addNew: 3,
  },


];

export const additionals = [
  {
    id: 1,
    subItems: [
      {
        id: 1,
        name: "Starlink Nomad цүнх",
        img: starlink_carry_bag,
        price: 100000,
      },
      {
        id: 5,
        name: "WiFi6 гадна зориулалтын Access point 6 omni antena",
        img: outdoor_wifi6,
        price: 1000000,
      },
      {
        id: 4,
        name: "WiFi5 гадна зориулалтын Access point 4 omni antena",
        img: outdoor_wifi6_small,
        price: 600000,
      },
      {
        id: 3,
        name: "WiFi5 гадна зориулалтын Access point 2 omni antena",
        img: outdoor_wifi_small,
        price: 400000,
      },
      {
        id: 2,
        name: "WiFi6 дотор зориулалтын Access point",
        img: indoor_wifi6,
        price: 350000,
      },
    ],
  },
  {
    id: 2,
    subItems: [
      {
        id: 1,
        name: "Starlink цүнх",
        img: starlink_carry_bag,
        price: 1000000,
      },
      { id: 2, name: "Indoor WiFi6 ", img: indoor_wifi6, price: 1000000 },
      {
        id: 3,
        name: "Outdoor WiFi small",
        img: outdoor_wifi_small,
        price: 1000000,
      },
      {
        id: 4,
        name: "Outdoor WiFi6 small",
        img: outdoor_wifi6_small,
        price: 1000000,
      },
    ],
  },
  {
    id: 3,
    subItems: [
      {
        id: 1,
        name: "Starlink цүнх",
        img: starlink_carry_bag,
        price: 1000000,
      },
      { id: 2, name: "Indoor WiFi6 ", img: indoor_wifi6, price: 1000000 },
      {
        id: 3,
        name: "Outdoor WiFi small",
        img: outdoor_wifi_small,
        price: 1000000,
      },
      {
        id: 4,
        name: "Outdoor WiFi6 small",
        img: outdoor_wifi6_small,
        price: 1000000,
      },
    ],
  },
];

export const priceRange = [
  { day: 1, price: 80000 },
  { day: 2, price: 80000 },
  { day: 3, price: 80000 },
  { day: 4, price: 80000 },
  { day: 5, price: 80000 },
  { day: 6, price: 80000 },
  { day: 7, price: 80000 },
  { day: 8, price: 80000 },
  { day: 9, price: 80000 },
  { day: 10, price: 70000 },
  { day: 11, price: 70000 },
  { day: 12, price: 70000 },
  { day: 13, price: 70000 },
  { day: 14, price: 70000 },
  { day: 15, price: 70000 },
  { day: 16, price: 70000 },
  { day: 17, price: 70000 },
  { day: 18, price: 70000 },
  { day: 19, price: 70000 },
  { day: 20, price: 60000 },
  { day: 21, price: 60000 },
  { day: 22, price: 60000 },
  { day: 23, price: 60000 },
];

export const pricePanel = [
  { day: 1, price: 80000 },
  { day: 2, price: 80000 },
  { day: 3, price: 80000 },
  { day: 4, price: 80000 },
  { day: 5, price: 80000 },
  { day: 6, price: 80000 },
  { day: 7, price: 80000 },
  { day: 8, price: 80000 },
  { day: 9, price: 80000 },
  { day: 10, price: 70000 },
  { day: 11, price: 70000 },
  { day: 12, price: 70000 },
  { day: 13, price: 70000 },
  { day: 14, price: 70000 },
  { day: 15, price: 70000 },
  { day: 16, price: 70000 },
  { day: 17, price: 70000 },
  { day: 18, price: 70000 },
  { day: 19, price: 70000 },
  { day: 20, price: 60000 },
  { day: 21, price: 60000 },
  { day: 22, price: 60000 },
  { day: 23, price: 60000 },
];
