import React, { useEffect, useState } from "react";
import "./App.css";
import Header from "./Header";
import Landing from "./Landing";
import WhatIsSL from "./components/starlink/WhatIsSL";
import { Tabs } from "antd";
import s1 from "./assets/solution.jpg";
import Toolbar from "./Toolbar";
import ToolbarLanding from "./ToolbarLanding";
import Intro2 from "./components/starlink/Intro2";
import Faq from "./components/faq/faq";
import { useLocation } from "react-router-dom";

const idList = ["landing", "starlink", "starlink2", "intro", "starlink3"];

const Home = () => {
  const [tabPosition] = useState("top");
  const [isVisible, setIsVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const location = useLocation();
  useEffect(() => {
    const detectScreenWidth = () => {
      if (window.innerWidth <= 768) {
        setIsMobile(true);
      }
    };

    detectScreenWidth();
    window.addEventListener("resize", detectScreenWidth);

    return () => {
      window.removeEventListener("resize", detectScreenWidth);
    };
  }, []);
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);

  const toggleVisibility = () => {
    const scrollContainer = document.querySelector(".background-container");
    if (scrollContainer.scrollTop > 850) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
    setCurrentSectionIndex(getCurrentSectionIndex());
  };

  const getCurrentSectionIndex = () => {
    for (let i = 0; i < idList.length; i++) {
      const section = document.getElementById(idList[i]);
      if (section) {
        const rect = section.getBoundingClientRect();
        if (rect.top >= 0 && rect.top < window.innerHeight) {
          return i;
        }
      }
    }
    return -1;
  };

  useEffect(() => {
    const scrollContainer = document.querySelector(".background-container");
    scrollContainer.addEventListener("scroll", toggleVisibility);
    return () => {
      scrollContainer.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  const tabItems = [
    {
      label: (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            color: "white",
            borderRadius: 10,
            fontSize: 18,
            justifyContent: "center",
            alignItems: "center",
            height: 25,
          }}
        >
          <span>Төслийн танилцуулга</span>
        </div>
      ),
      key: "1",
      children: (
        <div
          className="shadow-4 tab-container"
          
        >
          <iframe
            id="video"
            class="videofull"
            src="https://www.youtube.com/embed/yEpNJpiTgHo?autoplay=1&mute=1&loop=1&controls=0&start=12&rel=0"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      ),
    },
    {
      label: (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            color: "white",
            borderRadius: 10,
            fontSize: 18,
            justifyContent: "center",
            alignItems: "center",
            height: 25,
          }}
        >
          <span>Шийдэл</span>
        </div>
      ),
      key: "2",
      children: (
        <div className="tab-container">
          <img
            src={s1}
            style={{ height: "100%", width: "100%" }}
            alt="solution"
          />
        </div>
      ),
    },
  ];
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const sectionId = params.get("id");

    if (sectionId) {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <>
      <div className="background-container">
        <div id="landing" className="section-main" style={{ padding: 0 }}>
          <Landing />
        </div>
        {!isMobile && (
          <>
            {isVisible ? (
              <Toolbar></Toolbar>
            ) : (
              <ToolbarLanding></ToolbarLanding>
            )}
          </>
        )}
        {isVisible ? <Header /> : <></>}
        <div className="section-main" id="starlink">
          <WhatIsSL />
        </div>
        <div id="starlink2" className="section-main">
          <div className="ssl-container-out">
            <div className="solution-tabs ">
              <Tabs tabPosition={tabPosition} items={tabItems} centered />
            </div>
          </div>
        </div>
        <div id="intro" className="section-main">
          <Intro2 />
        </div>

        <div id="starlink3" className="section-main" style={{ height: "100%" }}>
          <Faq />
        </div>
      </div>
    </>
  );
};

export default Home;
