import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "./assets/black_logo_long.png";

const Header = () => {
  const navigate = useNavigate();
  const [cartCount2, setCartCount] = useState(0);
  useEffect(() => {
    const cartItems = localStorage.getItem("cartItems");
    setCartCount(cartItems ? JSON.parse(cartItems).length : 0);
  }, [cartCount2]);

  const callShoppingCard = () => {
    navigate(`/sp`);
  };

  return (
    <div className="header" style={{ background: "rgb(240, 241, 242)" }}>
      <div className="header-container">
        <div
          onClick={() => navigate(`/home`)}
          className="header-img"
        >
          <img
            src={logo}
            alt="logo"
            style={{ height: "100%", width: "100%" }}
          />
        </div>
        <div className="header-img">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              position: "relative",
            }}
            onClick={callShoppingCard}
          >
            <img
              src="https://png.pngtree.com/element_our/20190531/ourmid/pngtree-shopping-cart-convenient-icon-image_1287807.jpg"
              alt="Shopping Bag"
              style={{ height: 24, width: 24 }}
            />
            <span
              style={{
                position: "absolute",
                top: -5,
                right: -10,
                background: "red",
                color: "white",
                borderRadius: "50%",
                padding: "0 5px",
                fontSize: "10px",
                fontWeight: "bold",
              }}
            >
              {cartCount2}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
