import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import indoor_wifi6 from "../../assets/others/indoor_wifi6.png";
import outdoor_wifi_small from "../../assets/others/outdoor_wifi_small.png";
import outdoor_wifi6_small from "../../assets/others/outdoor_wifi6_small.png";
import outdoor_wifi6 from "../../assets/others/outdoor_wifi6.png";
import starlink_carry_bag from "../../assets/others/starlink_carry_bag.png";
import left from "../../assets/icons/left.png";
import { products } from "../Dummy";

const AdditionalList = () => {
  const ITEMS_PER_PAGE = 5;

  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [displayItems, setDisplayItems] = useState([]);

  useEffect(() => {
    // Update display items based on the current index
    const startIndex = currentIndex * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    setDisplayItems(products.slice(startIndex, endIndex));

    // Automatically move to the next set of items every 5 seconds
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === Math.floor(products.length / ITEMS_PER_PAGE) - 1
          ? 0
          : prevIndex + 1
      );
    }, 5000);

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, [currentIndex, products]);

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0
        ? Math.floor(products.length / ITEMS_PER_PAGE) - 1
        : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === Math.floor(products.length / ITEMS_PER_PAGE) - 1
        ? 0
        : prevIndex + 1
    );
  };

  const handleAdditionalSelect = (itemId) => {
    navigate(`/buy/${itemId}`);
  };
  return (
    <div className="add-list">
      <h4 className="add-title">БУСАД БАРАА</h4>
      <div className="add-container">
        <div className="add-img-cont-1">
          <img
            className="i-cs"
            src={left}
            onClick={handlePrevious}
            alt="Previous"
          />
        </div>
        <div className="add-sub">
          {displayItems.map((item) => (
            <div key={item.id} className="bf-c-main">
              <div
                className="bf-c"
                onClick={() => handleAdditionalSelect(item.id)}
              >
                <img alt="pocket" className="add-img" src={item.img} />
              </div>
              <p className="link-text" onClick={() => navigate(`/buy-list`)}>
                {item.name}
              </p>
              <h6 className="amount">MNT {item.price.toLocaleString()}</h6>
            </div>
          ))}
        </div>
        <div className="add-img-cont-1">
          <img src={left} className="i-cs" onClick={handleNext} alt="Next" />
        </div>
      </div>
    </div>
  );
};

export default AdditionalList;
