import React from "react";
import p2 from "../../assets/product_gen2.png";
import Starlink from "../section/starlink";

const WhatIsSL = () => {
  return (
    <div>
      <div className="ssl-container-out">
        <h3 className="ssl-header">Starlink Nomad гэж юу вэ?</h3>
        <div className="ssl-container">
          <div className="ssl-img-cont ">
            <img className="pr-img shadow-4 ssl-img" src={p2} alt="Product 2" />
          </div>
          <div className="ssl-second">
            <Starlink></Starlink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatIsSL;
