import React, { useEffect, useState } from "react";
import ic_phone from "../../assets/icons/ic_phone.png";
import ic_call from "../../assets/icons/ic_call.png";
import taiwan from "../../assets/gallery/Taiwan.png";
import royal from "../../assets/gallery/Royal.png";
import saruul from "../../assets/gallery/Saruul.png";
import FooterL from "../../Footer";
import Header from "../../Header";
import InstagramEmbed from "./InstaEmbed";
import FacebookEmbed from "./FbEmbed";
import ToolbarSecond from "../../ToolbarSecond";

const Contact = () => {
  const [isMobile, setIsMobile] = useState(false);
  // const callGoogleApi = () => {
  //   window.open("https://maps.app.goo.gl/JdViJ3NKc9T17aH39", "_blank");
  // };
  useEffect(() => {
    const detectScreenWidth = () => {
      const isMobile = window.innerWidth <= 768;
      if (isMobile) {
        setIsMobile(true);
      } else { setIsMobile(false) }
    };

    detectScreenWidth();
    window.addEventListener("resize", detectScreenWidth);

    return () => {
      window.removeEventListener("resize", detectScreenWidth);
    };
  }, []);
  const items = [
    {
      name: "Дэлгүүр",
      address:
        "Улаанбаатар хот, Сүхбаатар дүүрэг 3-р хороо, Royal Tower, 503 тоот (Циркийн урд)    ",
      f: "88054610",
      phone: "75990033, 99996384",
      email: "info@starlinknomad.mn",
      img: royal,
      i_link: "https://maps.app.goo.gl/9PMvWH7vfeUZzUeWA",
    },
    {
      name: "Засварын төв",
      address:
        "Улаанбаатар хот, Сүхбаатар дүүрэг 3-р хороо, Саруул захын зүүн хойно, Саруул оффис, 402 тоот",
      phone: "75990033, 99997428",
      email: "service@starlinknomad.mn",
      img: saruul,
      i_link: "https://maps.app.goo.gl/8b2BG7qaaxxe7a3R6",
    },
    {
      name: "Шийдлийн төв",
      address:
        "Улаанбаатар хот, Сүхбаатар дүүрэг 3-р хороо, Монгол-Тайвань төв, 511 тоот(Урт цагааны урд)",
      phone: "75990044, 99996030",
      email: "solution@starlinknomad.mn",
      img: taiwan,
      i_link: "https://maps.app.goo.gl/SLtCmzcGiSjAjdQE8",
    },
  ];

  const handleUrl = (mapUrl) => {
    window.open(mapUrl, "_blank");
  };

  return (
    <div>
      <Header></Header>
      {!isMobile && <ToolbarSecond></ToolbarSecond>}
      <div
        className="contact-main"
      >
        <div
          className="contact-second"
        >
          <div
            className="contact-third"
          >
            <div className="contact-img">
              <img
                className="starlink-icon"
                src={ic_call}
                alt="iccall"
              ></img>
            </div>
            <h3 style={{ marginLeft: 20 }}> {75990033}</h3>
          </div>
          <div

            className="contact-card"
          >
            {items.map((item) => (
              <>
                <div className="c-card-item">
                  <h3 style={{ width: "100%" }}>{item.name}</h3>
                  <div className="c-card-item-h">
                    <div
                      className="c-card-body"
                    >
                      <div className="c-card-img">
                        <img
                          className="starlink-icon"
                          src={ic_phone}
                          alt="iccall"
                        ></img>
                      </div>
                      <div
                        className="contact-address"
                      >
                        <span className="contact-address-t">
                          {item.address}
                        </span>
                        {item.name === "Дэлгүүр" && (
                          <div style={{ marginLeft: 10, marginTop: -20 }}>
                            <span
                              style={{
                                fontWeight: "bold",
                                color: "black",
                              }}
                            >
                              Санхүү:
                            </span>
                            <span style={{ marginLeft: 10 }}>{item.f}</span>
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      className="contact-address2"
                    >
                      <div
                        className="contact-a-img"
                      >
                        <img
                          className="starlink-icon"
                          src={ic_phone}

                          alt="icphone"
                        ></img>
                      </div>
                      <div style={{ marginLeft: 20, fontSize: 16 }}>
                        {item.name === "Дэлгүүр" && (
                          <span style={{ fontWeight: "bold" }}>
                            Борлуулагч:
                          </span>
                        )}
                        {/* <span style={{ fontWeight: "bold" }}>Борлуулагч:</span> */}
                        <span style={{ marginLeft: 10 }}>{item.phone}</span>
                      </div>
                    </div>
                    <div
                      className="contact-address2"
                    >
                      <div style={{ width: 10, height: 10 }}>
                        <img

                          alt="icaddress"
                          style={{ width: "100%", height: "100%" }}
                          src="https://e7.pngegg.com/pngimages/914/782/png-clipart-white-and-gray-mail-application-email-computer-icons-grey-mobile-phones-grey-background-miscellaneous-angle.png"
                        ></img>
                      </div>
                      <div style={{ marginLeft: 20, fontSize: 16 }}>
                        <span style={{ marginLeft: 10 }}>{item.email}</span>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{ marginTop: 70 }}
                    onClick={() => handleUrl(item.i_link)}
                  >
                    <img
                      style={{
                        border: "1px solid #c8c6c7",
                      }}

                      alt="iccall"
                      src={item.img}
                    ></img>
                  </div>
                </div>
              </>
            ))}
          </div>
          <hr style={{ marginTop: 20 }}></hr>
          <div className="contact-fb">
            <div className="contact-fb-embed">
              <FacebookEmbed></FacebookEmbed>
            </div>
            <div className="contact-insta-embed">
              <InstagramEmbed></InstagramEmbed>
            </div>
            <div className="contact-tw-embed">
              <iframe
                width="440"
                height="470"
                src="https://www.youtube.com/embed/yEpNJpiTgHo?si=Z_2f5vebjiop7G01"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      <FooterL></FooterL>
    </div>
  );
};

export default Contact;
