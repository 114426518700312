import React, { useEffect, useState } from "react";
import { Tabs } from "antd";


import plug from "../../assets/device-icons/220plug.png";
import bag from "../../assets/device-icons/bag.png";
import cable from "../../assets/device-icons/cable.png";
import magnet from "../../assets/device-icons/magnet.png";
import main from "../../assets/device-icons/main.png";
import ppf from "../../assets/device-icons/ppf.png";
import suck from "../../assets/device-icons/suck.png";

import main1 from "../../assets/device-icons/sets/main1.png";
import hajuu from "../../assets/device-icons/sets/hajuu.png";
import hajuu2 from "../../assets/device-icons/sets/hajuu2.png";
import dood from "../../assets/device-icons/sets/dood.png";
import jin from "../../assets/device-icons/sets/jin.png";
import map_b from "../../assets/icons/feature/map.webm";
import users_b from "../../assets/icons/feature/users.webm";
import high_b from "../../assets/icons/feature/high.webm";
import support_b from "../../assets/icons/feature/support.webm";
import chance_b from "../../assets/icons/feature/chance.webm";
import wifi6_b from "../../assets/icons/feature/wifi6.webm";
import all_b from "../../assets/icons/feature/all.webm";
import b_b from "../../assets/icons/feature/12b.webm";
import car_b from "../../assets/icons/feature/car.webm";
import secure_b from "../../assets/icons/feature/secure.webm";
import useful_b from "../../assets/icons/feature/useful.webm";

import {
  ShareAltOutlined,
  ProductOutlined,
  LaptopOutlined,
  ApiOutlined,
  AppstoreOutlined,
  InteractionOutlined,
} from "@ant-design/icons";

const { TabPane } = Tabs;

const Starlink = () => {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const detectScreenWidth = () => {
      if (window.innerWidth <= 768) {
        setIsMobile(true);
      }
    };

    detectScreenWidth();
    window.addEventListener("resize", detectScreenWidth);

    return () => {
      window.removeEventListener("resize", detectScreenWidth);
    };
  }, []);
  return (
    <div
      style={{
        marginTop: "auto",
        marginBottom: "auto",
      }}
    >
      <Tabs defaultActiveKey="1" tabPosition="top" className="st-tab">
        {isMobile === false && (
          <>
            <TabPane
              tab={
                <div style={{ color: "white" }}>
                  <ShareAltOutlined />
                  <span className="tabPane">NOMAD</span>
                </div>
              }
              key="1"
            >
              <div className="starlink-card">
                <div className="st-padding">
                  <p className="pr-p">
                    Монгол орны байгалийн онцлог, замын нөхцөл, аврагчид, аялагчид, малчдад зориулан өөрчилж хялбаршуулсан Старлинкийн сансрын интернэтийн хөдөлгөөнт төхөөрөмж Starlink Nomad.
                  </p>
                  <p className="pr-p">
                    Манай орны өргөн уудам нутгийн аль ч цэгт, харилцаа холбоо хүрээгүй алслагдмал хязгаарт малчид хотондоо, аялагчид авто машинаар явж байхдаа өндөр хурдны интернэтэд холбогдох хаялбар шийдэл юм.
                  </p>
                  <p className="pr-p">
                    Бид төхөөрөмжөө Монгол орныхоо 21 аймгийн нутгаар явж тэсгим хүйтэн, аагим халуун, цастайд  бороотойд, салхи шуурга, хүнд хэцүү замуудад туршиж, Хил хамгаалах, Онцгой байдал, Цэрэг армийн байгууллагуудад хэрэглүүлж үзсэний үндсэн дээр хөгжүүлэлтээ хийж зах зээлд нэвтрүүлээд байна.
                  </p>
                  <p className="pr-p">
                    Starlink Nomad төхөөрөмжийг бүтээхдээ хэрэглэхэд хялбар байх бүгд нэг дор багтсан байх + технологийн хувьд шинэлэг байх + эдэлгээ даах чанартай эд ангиудаас бүрдүүлэх + сүлжээг өргөтгөх боломжуудтай байх гэсэн үндсэн 4 шалгуурыг тавьж ажилласан.
                  </p>
                </div>
              </div>
            </TabPane>
            <TabPane
              tab={
                <div className="white-color">
                  <ShareAltOutlined />
                  <span className="tabPane">ОНЦЛОГ</span>
                </div>
              }
              key="2"
            >
              <div className="starlink-card">
                <ul>
                  <li className="starlink-li">
                    <div className="flex-image-container">
                      <div className="icon-cell">
                        {/* <img src={map} alt="map" className="starlink-icon" /> */}
                        <video
                          id="video"
                          autoPlay
                          muted
                          loop
                          playsInline
                          src={map_b}
                          className="starlink-icon"
                        ></video>
                      </div>
                      <p className="starlink-desc">
                        Хаана ч хэзээ ч холбогдоно
                      </p>
                    </div>
                    <div className="flex-image-container">
                      <div className="icon-cell">
                        <video
                          id="video"
                          autoPlay
                          muted
                          loop
                          playsInline
                          src={car_b}
                          className="starlink-icon"
                        ></video>
                      </div>
                      <p className="starlink-desc">Хөдөлгөөн дунд холбогдоно</p>
                    </div>
                    <div className="flex-image-container">
                      <div className="icon-cell2">
                        <video
                          id="video"
                          autoPlay
                          muted
                          loop
                          playsInline
                          src={b_b}
                          className="starlink-icon"
                        ></video>
                      </div>
                      <p className="starlink-desc">
                        12в, 24в, 220в олон үүсгэвэр дэмжинэ
                      </p>
                    </div>
                    <div className="flex-image-container">
                      <div className="icon-cell2">
                        <video
                          id="video"
                          autoPlay
                          muted
                          loop
                          playsInline
                          src={all_b}
                          className="starlink-icon"
                        ></video>
                      </div>
                      <p className="starlink-desc">Бүгд нэг хайрцагт багтсан</p>
                    </div>
                  </li>
                  <li className="starlink-li">
                    <div className="flex-image-container">
                      <div className="icon-cell">
                        <video
                          id="video"
                          autoPlay
                          muted
                          loop
                          playsInline
                          src={useful_b}
                          className="starlink-icon"
                        ></video>
                      </div>
                      <p className="starlink-desc">Хэрэглэхэд хялбар</p>
                    </div>
                    <div className="flex-image-container">
                      <div className="icon-cell">
                        <video
                          id="video"
                          autoPlay
                          muted
                          loop
                          playsInline
                          src={secure_b}
                          className="starlink-icon"
                        ></video>
                      </div>
                      <p className="starlink-desc">
                        Бат бөх ус, тоосны хамгаалалт
                      </p>
                    </div>
                    <div className="flex-image-container">
                      <div className="icon-cell">
                        <video
                          id="video"
                          autoPlay
                          muted
                          loop
                          playsInline
                          src={chance_b}
                          className="starlink-icon"
                        ></video>
                      </div>
                      <p className="starlink-desc">Нэмэлт өргөтгөлийн боломж</p>
                    </div>
                    <div className="flex-image-container">
                      <div className="icon-cell">
                        <video
                          id="video"
                          autoPlay
                          muted
                          loop
                          playsInline
                          src={support_b}
                          className="starlink-icon"
                        ></video>
                      </div>
                      <p className="starlink-desc">
                        Баталгаа, борлуулалтын дараах үйлчилгээ
                      </p>
                    </div>
                  </li>
                  <li className="starlink-li">
                    <div className="flex-image-container">
                      <div className="icon-cell">
                        <video
                          id="video"
                          autoPlay
                          muted
                          loop
                          playsInline
                          src={high_b}
                          className="starlink-icon"
                        ></video>
                      </div>
                      <p className="starlink-desc">Өндөр хурд</p>
                    </div>
                    <div className="flex-image-container">
                      <div className="icon-cell">
                        <video
                          id="video"
                          autoPlay
                          muted
                          loop
                          playsInline
                          src={wifi6_b}
                          className="starlink-icon"
                        ></video>
                      </div>
                      <p className="starlink-desc">6-р үеийн утасгүй сүлжээ</p>
                    </div>
                    <div className="flex-image-container">
                      <div className="icon-cell">
                        <video
                          id="video"
                          autoPlay
                          muted
                          loop
                          playsInline
                          src={users_b}
                          className="starlink-icon"
                        ></video>
                      </div>
                      <p className="starlink-desc">Олон хэрэглэгч холбоно</p>
                    </div>
                    <div className="flex-image-container"></div>
                  </li>
                </ul>
              </div>
            </TabPane>
            <TabPane
              tab={
                <div className="white-color">
                  <ApiOutlined />
                  <span className="tabPane">ТЕХНИК ҮЗҮҮЛЭЛТ</span>
                </div>
              }
              key="3"
            >
              <div className="starlink-card">
                <div className="tech-cont">
                  <ul className="tech-ul-1">
                    <li>Тавагны харах талбар:</li>
                    <li>Сүлжээний гаралт:</li>
                    <li>WiFi технологи</li>
                    <li>Радио:</li>
                    <li>Хамгаалалт:</li>
                    <li style={{ height: 40, marginBottom: 10 }}>
                      Хамрах хүрээ:
                    </li>
                    <li>Цахилгааны оролт:</li>
                    <li>Эрчим хүч зарцуулалт:</li>
                    <li>Ажиллах температур:</li>
                    <li>Хэрэглэгч холбох тоо:</li>
                    <li>Орчны хамгаалалт:</li>
                  </ul>
                  <ul className="tech-ul-2">
                    <li>Дээшээ байрлалд 120 градус</li>
                    <li>Gen2 1 гегабит (нэмэлтээр) x Lan*1. Gen3 Lan*2 </li>
                    <li>Gen2 802.11ас 802.11 WiFi 5/ax WiFi 6. Gen3 ax WiFi6</li>
                    <li>Dual band -3 x 3 MIMO / Tri Band 4 x 4 MU-MIMO*</li>
                    <li>WPA2 / WPA3*</li>
                    <li style={{ height: 40, marginBottom: 10 }}>
                      185m2 / 300m2 . хүртэл* Байршил, саад, барилгын
                      материалаас хамаарна
                    </li>
                    <li>Тогтмол 12в, 24в. Хувьсах 220в.</li>
                    <li>Gen2 60Wh. Gen3 90Wh</li>
                    <li>-45 ~ +50</li>
                    <li>128/235 * хүртэл</li>
                    <li>IP67 ус, тоосны хамгаалалт</li>
                  </ul>
                </div>
              </div>
            </TabPane>

            <TabPane
              tab={
                <div className="white-color">
                  <ProductOutlined />
                  <span className="tabPane">ФИЗИК ҮЗҮҮЛЭЛТ</span>
                </div>
              }
              key="4"
            >
              <div className="starlink-card">
                <div className="fizik-cont">
                  <div className="fz-1">538 mm</div>
                  <div className="fz-2">
                    <div className="fz-3">
                      Дээд тал: Старлинк таваг + 4мм тунгалаг хавтан +
                      хамгаалалтын хуулга
                    </div>
                    <div className="fz-4">
                      <img src={main1} alt="logowhite" className="fz-img" />
                      <div className="fz-text">330 mm</div>
                    </div>
                  </div>
                  <div className="fizik-cont">
                    <div className="fz-c-2">
                      <div className="fizik-cont width-150">
                        <div className="fz-text">
                          12в, 24в тогтмол гүйдлийн оролт
                        </div>
                        <div className="fz-text">
                          220в хувьсах гүйдлийн оролт
                        </div>
                      </div>
                      <div>
                        <img src={hajuu} alt="hajuu" className="fz-img-2" />
                      </div>
                      <div className="fizik-cont width-150">
                        <div className="fz-text ">Асаах, унтраах товч</div>
                        <div className="fz-text ">
                          LAN(Rj45) сүлжээний гаралт + POE(нэмэлтээр)
                        </div>
                        <div className="fz-top-t">42 mm</div>
                      </div>
                    </div>
                    <div className="fz-5">
                      <div>45 mm</div>
                      <div className="fz-6">10 mm</div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: -30,
                      marginTop: 10,
                    }}
                  >
                    <div
                      style={{
                        marginLeft: 50,
                      }}
                    >
                      <img
                        src={jin}
                        alt="jin"
                        style={{
                          borderRadius: 12,
                          width: 28,
                          height: 50,
                        }}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        marginTop: 10,
                      }}
                    >
                      <div
                        style={{
                          textAlign: "left",
                          justifyContent: "center",
                          marginLeft: 30,
                          width: 120,
                          fontSize: 12,
                        }}
                      >
                        Жин: 4.5кг
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div
                      style={{
                        //   width: 300,
                        padding: 20,
                        marginTop: 20,
                        marginLeft: 10,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <div
                          style={{
                            marginLeft: 30,
                            fontSize: 12,
                          }}
                        >
                          Хажуу тал: Фибер карбон + хамгаалалтын хуулга
                        </div>
                      </div>
                      <div
                        style={{
                          marginLeft: 0,
                        }}
                      >
                        <img
                          src={hajuu2}
                          alt="hajuu2"
                          style={{
                            borderRadius: 12,
                            width: 250,
                            height: 50,
                          }}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <div
                          style={{
                            textAlign: "left",
                            marginLeft: 0,
                            fontSize: 12,
                          }}
                        >
                          Соронзон суурь
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      width: 300,
                      marginLeft: 20,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: 400,
                        marginLeft: 40,
                        marginTop: -50,
                      }}
                    >
                      <div
                        style={{
                          textAlign: "center",
                          justifyContent: "center",
                          marginLeft: 0,
                          fontSize: 10,
                          width: 120,
                        }}
                      >
                        Доод тал: Хөнгөн цагаан хавтан
                      </div>
                      <div
                        style={{
                          textAlign: "center",
                          justifyContent: "center",
                          marginLeft: -10,
                          fontSize: 10,
                          width: 120,
                        }}
                      >
                        WiFi роутерийн ресет товч
                      </div>
                    </div>
                    <div
                      style={{
                        marginLeft: 60,
                        marginTop: -10,
                      }}
                    >
                      <img
                        src={dood}
                        alt="dood"
                        style={{
                          borderRadius: 12,
                          width: 220,
                          height: 140,
                        }}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        width: 400,
                      }}
                    >
                      <div
                        style={{
                          textAlign: "left",
                          justifyContent: "center",
                          marginLeft: -120,
                          marginTop: -10,
                          fontSize: 10,
                          width: 200,
                          // width: "50%",
                        }}
                      >
                        М6 хэмжээтэй шурагны нүх (соронзон суурь, бумбан суурь,
                        эрэгдэх суурь)
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabPane>
            <TabPane
              tab={
                <div
                  style={{
                    color: "white",
                    marginLeft: 12,
                  }}
                >
                  <LaptopOutlined />
                  <span className="tabPane">БҮРДЭЛ</span>
                </div>
              }
              key="5"
            >
              <div className="starlink-card">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    // marginTop: -40,
                  }}
                >
                  <div className="flex-image-container">
                    <div className="flex-img-container">
                      <img src={main} alt="main" className="starlink-icon" />
                      <p style={{ marginLeft: 10 }}>Үндсэн төхөөрөмж</p>
                    </div>
                  </div>
                  <div style={{ marginTop: -40 }}>
                    <ul style={{ marginTop: 0 }}>
                      <div>
                        <li className="starlink-li" style={{ marginTop: 60 }}>
                          <div
                            className="flex-image-container2"
                            style={{
                              marginLeft: 0,
                              width: 140,
                            }}
                          >
                            <div className="icon-cell">
                              <img
                                src={magnet}
                                alt="magnet"
                                className="starlink-icon"
                              />
                            </div>
                            <p className="starlink-desc-fizik">
                              Төмөр гадаргууд суурилуулах соронз 4ш
                            </p>
                          </div>
                          <div
                            className="flex-image-container"
                            style={{
                              marginLeft: 20,
                              width: 140,
                            }}
                          >
                            <div className="icon-cell2">
                              <img
                                src={suck}
                                alt="power"
                                className="starlink-icon"
                              />
                            </div>
                            <p
                              className="starlink-desc-fizik"
                              style={{ width: 140 }}
                            >
                              Бумбан суурь 4ш (Хөнгөн цагаан, шилэн гадаргууд)
                              Нэмэлт*
                            </p>
                          </div>
                          <div
                            className="flex-image-container"
                            style={{
                              //   marginTop: 40,
                              marginLeft: 20,
                              width: 140,
                            }}
                          >
                            <div className="icon-cell2">
                              <img
                                src={cable}
                                alt="allinone"
                                className="starlink-icon"
                              />
                            </div>
                            <p className="starlink-desc-fizik">
                              12в, 24в тогтмол гүйдлийн кабель (3 м)
                            </p>
                          </div>
                        </li>
                      </div>
                      <div>
                        <li className="starlink-li">
                          <div
                            className="flex-image-container"
                            style={{
                              marginTop: 40,
                              marginLeft: -20,
                              width: 140,
                            }}
                          >
                            <div className="icon-cell">
                              <img
                                src={ppf}
                                alt="durablity"
                                className="starlink-icon"
                              />
                            </div>
                            <p className="starlink-desc-fizik">
                              Зурагдалтаас хамгаалах наалт (8ш)
                            </p>
                          </div>
                          <div
                            className="flex-image-container"
                            style={{
                              marginTop: 40,
                              marginLeft: 20,
                              width: 140,
                            }}
                          >
                            <div className="icon-cell">
                              <img
                                src={plug}
                                alt="improve"
                                className="starlink-icon"
                              />
                            </div>
                            <p className="starlink-desc-fizik">
                              220в хувьсах гүйдлийн залгуур
                            </p>
                          </div>
                          <div
                            className="flex-image-container"
                            style={{
                              marginTop: 40,
                              marginLeft: 20,
                              width: 140,
                            }}
                          >
                            <div className="icon-cell">
                              <img
                                src={bag}
                                alt="afterservice"
                                className="starlink-icon"
                              />
                            </div>
                            <p className="starlink-desc-fizik">
                              Дагалдах хэрэгслийн уут
                            </p>
                          </div>
                        </li>
                      </div>
                    </ul>
                  </div>
                </div>
              </div>
            </TabPane>
          </>
        )}
        {isMobile && (
          <>
            <TabPane
              tab={
                <div className="white-color">
                  <ShareAltOutlined />
                  <span className="st-tab-text">NOMAD</span>
                </div>
              }
              key="1"
            >
              <div className="starlink-card">
                <div className="st-padding">
                  <p className="pr-p">
                    Монгол орны байгаль цаг агаар, замын нөхцөл, аврагчид,
                    аялагчид, малчдад зориулан өөрчилж сайжруулан хялбаршуулсан
                    Старлинкийн сансрын интернэтийн хөдөлгөөнт төхөөрөмж
                    Starlink Nomad.
                  </p>
                  <p className="pr-p">
                    Манай орны өргөн уудам нутгийн аль ч цэгт харилцаа холбоо
                    хүрээгүй алслагдмал хязгаарт малчид хотондоо, аялагчид авто
                    машинаар явж байхдаа өндөр хурдны интернэтэд холбогдох
                    хаялбар шийдэл юм.
                  </p>
                  <p className="pr-p">
                    Бид төхөөрөмжөө 21 аймгийн нутгаар явж халуун хүйтэн, цас
                    бороо, салхи шуурга, хүнд хэцүү замуудад туршиж, Хил
                    хамгаалах, Онцгой байдал, Цэрэг армийн байгууллагуудад
                    хэрэглүүлж үзсэний үндсэн дээр хөгжүүлэлтээ хийж зах зээлд
                    нэвтрүүлээд байна.
                  </p>
                  <p className="pr-p">
                    Starlink Nomad төхөөрөмжийг бүтээхдээ хэрэглэхэд хялбар байх
                    + технологийн хувьд шинэ үеийнх байх + эдэлгээ даах чанартай
                    эд ангиудаас бүрдүүлэх + сүлжээг өргөтгөх боломжуудтай байх
                    гэсэн үндсэн 4 шалгуурын тавьж ажилласан.
                  </p>
                </div>
              </div>
            </TabPane>
            <TabPane
              tab={
                <div className="white-color">
                  <ShareAltOutlined />
                  <span className="st-tab-text">ОНЦЛОГ</span>
                </div>
              }
              key="2"
            >
              <div className="starlink-card">
                <ul>
                  <li className="starlink-li">
                    <div className="flex-image-container">
                      <div className="icon-cell">
                        <video
                          id="video"
                          autoPlay
                          muted
                          loop
                          playsInline
                          src={map_b}
                          className="starlink-icon"
                        ></video>
                      </div>
                      <p className="starlink-desc">
                        Хаана ч хэзээ ч холбогдоно
                      </p>
                    </div>
                    <div className="flex-image-container">
                      <div className="icon-cell">
                        <video
                          id="video"
                          autoPlay
                          muted
                          loop
                          playsInline
                          src={car_b}
                          className="starlink-icon"
                        ></video>
                      </div>
                      <p className="starlink-desc">Хөдөлгөөн дунд холбогдоно</p>
                    </div>
                    <div className="flex-image-container">
                      <div className="icon-cell2">
                        <video
                          id="video"
                          autoPlay
                          muted
                          loop
                          playsInline
                          src={b_b}
                          className="starlink-icon"
                        ></video>
                      </div>
                      <p className="starlink-desc">
                        12в, 24в, 220в олон үүсгэвэр дэмжинэ
                      </p>
                    </div>
                  </li>
                  <li className="starlink-li">
                    <div className="flex-image-container">
                      <div className="icon-cell">
                        <video
                          id="video"
                          autoPlay
                          muted
                          loop
                          playsInline
                          src={useful_b}
                          className="starlink-icon"
                        ></video>
                      </div>
                      <p className="starlink-desc">Хэрэглэхэд хялбар</p>
                    </div>
                    <div className="flex-image-container">
                      <div className="icon-cell">
                        <video
                          id="video"
                          autoPlay
                          muted
                          loop
                          playsInline
                          src={secure_b}
                          className="starlink-icon"
                        ></video>
                      </div>
                      <p className="starlink-desc">
                        Бат бөх ус, тоосны хамгаалалт
                      </p>
                    </div>
                    <div className="flex-image-container">
                      <div className="icon-cell">
                        <video
                          id="video"
                          autoPlay
                          muted
                          loop
                          playsInline
                          src={chance_b}
                          className="starlink-icon"
                        ></video>
                      </div>
                      <p className="starlink-desc">Нэмэлт өргөтгөлийн боломж</p>
                    </div>
                  </li>
                  <li className="starlink-li">
                    <div className="flex-image-container">
                      <div className="icon-cell">
                        <video
                          id="video"
                          autoPlay
                          muted
                          loop
                          playsInline
                          src={high_b}
                          className="starlink-icon"
                        ></video>
                      </div>
                      <p className="starlink-desc">Өндөр хурд</p>
                    </div>
                    <div className="flex-image-container">
                      <div className="icon-cell">
                        <video
                          id="video"
                          autoPlay
                          muted
                          loop
                          playsInline
                          src={wifi6_b}
                          className="starlink-icon"
                        ></video>
                      </div>
                      <p className="starlink-desc">
                        6-р үеийн утасгүй сүлжээ
                      </p>
                    </div>
                    <div className="flex-image-container">
                      <div className="icon-cell">
                        <video
                          id="video"
                          autoPlay
                          muted
                          loop
                          playsInline
                          src={users_b}
                          className="starlink-icon"
                        ></video>
                      </div>
                      <p className="starlink-desc">Олон хэрэглэгч холбоно</p>
                    </div>
                  </li>
                  <li className="starlink-li">
                    <div className="flex-image-container">
                      <div className="icon-cell">
                        <video
                          id="video"
                          autoPlay
                          muted
                          loop
                          playsInline
                          src={all_b}
                          className="starlink-icon"
                        ></video>
                      </div>
                      <p className="starlink-desc">Бүгд нэг хайрцагт багтсан</p>
                    </div>
                    <div className="flex-image-container">
                      <div className="icon-cell">
                        <video
                          id="video"
                          autoPlay
                          muted
                          loop
                          playsInline
                          src={support_b}
                          className="starlink-icon"
                        ></video>
                      </div>
                      <p className="starlink-desc">
                        Баталгаа, борлуулалтын дараах үйлчилгээ
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </TabPane>
            <TabPane
              tab={
                <div className="white-color">
                  <AppstoreOutlined />
                  <span className="st-tab-text">ТЕХНИК ҮЗҮҮЛЭЛТ</span>
                </div>
              }
              key="3"
            >
              <div className="starlink-card">
                <div className="tech-cont">
                  <div className="tech-cont-list">
                    <p className="list-a">Тавагны харах талбар:</p>
                    <p className="list-b"> 1 гегабит (нэмэлтээр) x Lan* 1</p>
                  </div>
                  <div className="tech-cont-list">
                    <p className="list-a">Сүлжээний гаралт:</p>
                    <p className="list-b">Gen2 1 гегабит (нэмэлтээр) x Lan*1. Gen3 Lan*2 </p>
                  </div>
                  <div className="tech-cont-list">
                    <p className="list-a">WiFi технологи:</p>
                    <p className="list-b">Gen2 802.11ас 802.11 WiFi 5/ax WiFi 6. Gen3 ax WiFi6</p>
                  </div>
                  <div className="tech-cont-list">
                    <p className="list-a">Радио:</p>
                    <p className="list-b">Dual band -3 x 3 MIMO / Tri Band 4 x 4 MU-MIMO*</p>
                  </div>
                  <div className="tech-cont-list">
                    <p className="list-a">Хамгаалалт:</p>
                    <p className="list-b">WPA2 / WPA3*</p>
                  </div>
                  <div className="tech-cont-list">
                    <p className="list-a">Хамрах хүрээ:</p>
                    <p className="list-b">185m2 / 300m2 . хүртэл* Байршил, саад, барилгын
                      материалаас хамаарна</p>
                  </div>
                  <div className="tech-cont-list">
                    <p className="list-a">Цахилгааны оролт:</p>
                    <p className="list-b">Тогтмол 12в, 24в. Хувьсах 220в.</p>
                  </div>
                  <div className="tech-cont-list">
                    <p className="list-a">Эрчим хүч зарцуулалт:</p>
                    <p className="list-b">Gen2 60Wh. Gen3 90Wh</p>
                  </div>
                  <div className="tech-cont-list">
                    <p className="list-a">Ажиллах температур:</p>
                    <p className="list-b">-45 ~ +50</p>
                  </div>
                  <div className="tech-cont-list">
                    <p className="list-a">Хэрэглэгч холбох тоо:</p>
                    <p className="list-b">128/235 * хүртэл</p>
                  </div>
                  <div className="tech-cont-list">
                    <p className="list-a">Орчны хамгаалалт:</p>
                    <p className="list-b">IP67 ус, тоосны хамгаалалт</p>
                  </div>

                </div>
              </div>
            </TabPane>
            <TabPane
              tab={
                <div className="white-color">
                  <InteractionOutlined />
                  <span className="st-tab-text">БҮРДЭЛ</span>
                </div>
              }
            >
              <div className="starlink-card">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <div
                    style={{
                      width: 150,
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <img src={main} alt="main" />
                    <p style={{ fontSize: 14, marginTop: 10 }}>Үндсэн төхөөрөмж</p>
                  </div>
                  <div>
                    <div
                      style={{
                        width: 120,
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                        marginTop: 30,
                        marginLeft: 40,
                      }}
                    >
                      <img
                        src={magnet}
                        alt="magnet"
                        style={{ width: 80, alignSelf: "end" }}
                      />
                      <p style={{ fontSize: 10, marginTop: 10 }}>
                        Төмөр гадаргууд суурилуулах соронз 4ш
                      </p>
                    </div>
                    <div
                      style={{
                        width: 120,
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        marginLeft: 40,
                        marginTop: 12,
                        // padding: 20,
                      }}
                    >
                      <img
                        src={suck}
                        alt="magnet"
                        style={{ width: 80, alignSelf: "end" }}
                      />
                      <p style={{ fontSize: 10, marginTop: 10 }}>
                        Бумбан суурь 4ш (Хөнгөн цагаан, шилэн гадаргууд) Нэмэлт*
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <div>
                    <div
                      style={{
                        width: 120,
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        marginLeft: 30,
                        marginTop: 23,
                      }}
                    >
                      <img
                        src={cable}
                        alt="magnet"
                        style={{
                          width: 70,
                          height: 100,
                          alignSelf: "center",
                        }}
                      />
                      <p style={{ fontSize: 10, marginTop: 10 }}>
                        12в, 24в тогтмол гүйдлийн кабель (3 м)
                      </p>
                    </div>
                    <div
                      style={{
                        width: 120,
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        marginLeft: 30,
                        marginTop: 40,
                      }}
                    >
                      <img
                        src={ppf}
                        alt="magnet"
                        style={{
                          width: 80,
                          height: 100,
                          alignSelf: "center",
                        }}
                      />
                      <p style={{ fontSize: 10, marginTop: 10 }}>
                        Зурагдалтаас хамгаалах наалт (8ш)
                      </p>
                    </div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: 140,
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        marginLeft: 60,
                        marginTop: 20,
                      }}
                    >
                      <img
                        src={plug}
                        alt="magnet"
                        style={{
                          width: 40,
                          height: 100,
                          alignSelf: "center",
                        }}
                      />
                      <p style={{ fontSize: 10, marginTop: 10 }}>
                        220в хувьсах гүйдлийн залгуур
                      </p>
                    </div>
                    <div
                      style={{
                        width: 130,
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        marginLeft: 50,
                        marginTop: 45,
                        marginBottom: 40,
                      }}
                    >
                      <img
                        src={bag}
                        alt="magnet"
                        style={{
                          width: 120,
                          height: 100,
                          alignSelf: "center",
                        }}
                      />
                      <p style={{ fontSize: 10, marginTop: 10 }}>Дагалдах хэрэгслийн уут</p>
                    </div>
                  </div>
                </div>
              </div>
            </TabPane>
            <TabPane
              tab={
                <div>
                  <span className="tabText">Физик үзүүлэлт</span>
                </div>
              }
              key="7"
            >
              <div className="starlink-card">
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        marginLeft: 20,
                        fontSize: 9,
                      }}
                    >
                      538 mm
                    </div>
                    <div style={{ width: 250, marginLeft: -35 }}>
                      <div
                        style={{
                          textAlign: "center",
                          justifyContent: "center",
                          marginTop: 20,
                          marginLeft: 30,
                          width: 200,
                          fontSize: 9,
                        }}
                      >
                        Дээд тал: Старлинк таваг + 4мм тунгалаг хавтан +
                        хамгаалалтын хуулга
                      </div>
                      <div style={{ width: 200, marginLeft: 60 }}>
                        <img
                          src={main1}
                          alt="logowhite"
                          style={{
                            borderRadius: 12,
                            width: 140,
                            height: 230,
                          }}
                        />
                        <div
                          style={{
                            textAlign: "center",
                            fontSize: 9,
                            marginLeft: -60,
                            marginTop: -5,
                          }}
                        >
                          330 mm
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: 60,
                        marginLeft: -20,
                      }}
                    >
                      <div>
                        <img
                          src={jin}
                          alt="jin"
                          style={{
                            borderRadius: 12,
                            width: 28,
                            height: 50,
                          }}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          marginTop: 10,
                        }}
                      >
                        <div
                          style={{
                            textAlign: "left",
                            justifyContent: "center",
                            marginLeft: 10,
                            width: 40,
                            fontSize: 10,
                          }}
                        >
                          Жин: 4.5кг
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: 10,
                    }}
                  >
                    <div
                      style={{
                        width: 300,
                        marginLeft: 20,

                        // padding: 20,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: 300,
                        }}
                      >
                        <div
                          style={{
                            textAlign: "center",
                            justifyContent: "center",
                            marginLeft: 40,
                            fontSize: 9,
                          }}
                        >
                          12в, 24в тогтмол гүйдлийн оролт
                        </div>
                        <div
                          style={{
                            textAlign: "center",
                            justifyContent: "center",
                            marginLeft: 50,
                            fontSize: 9,
                          }}
                        >
                          220в хувьсах гүйдлийн оролт
                        </div>
                      </div>
                      <div
                        style={{
                          marginLeft: 40,
                        }}
                      >
                        <img
                          src={hajuu}
                          alt="hajuu"
                          style={{
                            borderRadius: 12,
                            width: 230,
                            height: 50,
                          }}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          width: 250,
                        }}
                      >
                        <div
                          style={{
                            textAlign: "center",
                            justifyContent: "center",
                            marginLeft: 30,
                            width: 120,
                            fontSize: 9,
                          }}
                        >
                          Асаах, унтраах товч
                        </div>
                        <div
                          style={{
                            textAlign: "center",
                            justifyContent: "center",
                            marginLeft: 30,
                            width: 220,
                            fontSize: 9,
                          }}
                        >
                          LAN(Rj45) сүлжээний гаралт + POE(нэмэлтээр)
                        </div>
                        <div
                          style={{
                            textAlign: "end",
                            justifyContent: "end",
                            // marginLeft: 20,
                            width: 60,
                            marginTop: -10,
                            fontSize: 9,
                          }}
                        >
                          42 mm
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        marginTop: 45,
                        marginLeft: -10,
                        fontSize: 9,
                      }}
                    >
                      <div style={{ width: 40 }}>45 mm</div>
                      <div style={{ marginTop: 5, marginLeft: 2 }}>10 mm</div>
                    </div>
                  </div>
                  <div
                    style={{
                      width: 300,
                      marginLeft: 20,
                      marginTop: 10,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: 300,
                        marginTop: 10,
                      }}
                    >
                      <div
                        style={{
                          textAlign: "center",
                          justifyContent: "center",
                          marginLeft: 30,
                          fontSize: 9,
                          width: 160,
                        }}
                      >
                        Доод тал: Хөнгөн цагаан хавтан
                      </div>
                      <div
                        style={{
                          textAlign: "center",
                          justifyContent: "center",
                          // marginLeft: 80,
                          fontSize: 9,
                          width: 160,
                        }}
                      >
                        WiFi роутерийн ресет товч
                      </div>
                    </div>
                    <div
                      style={{
                        marginLeft: 40,
                        marginTop: -10,
                      }}
                    >
                      <img
                        src={dood}
                        alt="dood"
                        style={{
                          borderRadius: 12,
                          width: 210,
                          height: 130,
                        }}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        width: 400,
                      }}
                    >
                      <div
                        style={{
                          textAlign: "left",
                          justifyContent: "center",
                          // marginLeft: 70,
                          marginTop: -20,
                          fontSize: 9,
                          width: "60%",
                        }}
                      >
                        М6 хэмжээтэй шурагны нүх (соронзон суурь, бумбан суурь,
                        эрэгдэх суурь)
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div
                    style={{
                      padding: 20,
                      marginLeft: 40,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <div style={{ fontSize: 9, width: 300 }}>
                        Хажуу тал: Фибер карбон + хамгаалалтын хуулга
                      </div>
                    </div>
                    <div style={{}}>
                      <img
                        src={hajuu2}
                        alt="hajuu2"
                        style={{
                          borderRadius: 12,
                          width: 210,
                          height: 60,
                        }}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <div
                        style={{
                          textAlign: "left",
                          marginTop: -10,
                          fontSize: 9,
                        }}
                      >
                        Соронзон суурь
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabPane>
          </>
        )}
      </Tabs>
    </div>
    // </div>
  );
};

export default Starlink;
